import { ICity } from './../../interfaces/location.interface';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { interpolateString } from '../../../core/constants/interpolate-string';

const RECENT_SEARCH_VERSION = 'VERSION_2';
const recentSearchKey = 'recentSearch';
const RECENT_SEARCH_COUNT = 5;

interface IRecentSearchItem {
    _id: string;
    name: string;
    imageUrl?: string;
    location?: string;
    spaceType?: string;
    redirectUrl: string;
    isLocation?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class RecentSearchesService {
    constructor(private storageService: StorageService) {}

    private cleanOutdatedRecentSearches() {
        this.storageService.removeLocalStorage(recentSearchKey);
    }

    private updateRecentSearch(recentSearches: IRecentSearchItem[], item: IRecentSearchItem) {
        // already exist in recent search
        if (recentSearches.find(recentSearch => recentSearch._id === item._id)) {
            return;
        }
        // More than max remove first
        if (recentSearches.length >= RECENT_SEARCH_COUNT) {
            recentSearches.pop();
        }
        recentSearches.unshift(item);
        const data = {
            version: RECENT_SEARCH_VERSION,
            data: recentSearches,
        };
        this.storageService.setLocalStorage(recentSearchKey, data);
    }

    private createRecentSearch(item: IRecentSearchItem) {
        const recentSearch = {
            version: RECENT_SEARCH_VERSION,
            data: [],
        };
        recentSearch.data.push(item);
        this.storageService.setLocalStorage(recentSearchKey, recentSearch);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setRecentSearch(selectedSuggestion: any, selectedCity: ICity) {
        let recentItem;
        if (selectedSuggestion.isLocation) {
            const { place_id, display, isLocation } = selectedSuggestion;
            const { slug } = selectedCity;
            recentItem = {
                _id: place_id,
                redirectUrl: `/${slug}/%{${interpolateString.PRODUCT_TYPE}}/%{${interpolateString.SPACE_TYPE}}-in-${slug}?placeId=${place_id}`,
                name: display,
                isLocation,
            };
        } else {
            const { _id, redirectUrl, name, spaceType, location, spaceImage } = selectedSuggestion;
            recentItem = {
                _id,
                imageUrl: spaceImage.url,
                redirectUrl,
                name,
                spaceType,
                location,
            };
        }
        const recentSearches = this.getRecentSearches();
        if (recentSearches.length) {
            this.updateRecentSearch(recentSearches, recentItem);
        } else {
            this.createRecentSearch(recentItem);
        }
    }

    getRecentSearches() {
        const recentSearches = this.storageService.getLocalStorage(recentSearchKey);
        if (recentSearches) {
            if (recentSearches.version === RECENT_SEARCH_VERSION) {
                return recentSearches.data;
            } else {
                this.cleanOutdatedRecentSearches();
                return [];
            }
        } else {
            return [];
        }
    }

    updateDeprecatedRecentSearchItems() {
        let recentSearches = this.getRecentSearches();
        if (recentSearches.length) {
            recentSearches = recentSearches.map(item => {
                if (item.redirectUrl.includes('coworking-space-in-')) {
                    item.redirectUrl = item.redirectUrl.replace('coworking-space-in-', '%{spaceType}-in-');
                }
                return item;
            });
        }
        this.storageService.setLocalStorage(recentSearchKey, recentSearches);
    }
}
