import { SelectCityService } from './shared/services/select-city/select-city.service';
import { RedirectManagerService } from './shared/services/redirect-manager/redirect-manager.service';
import { ErrorTrackingService } from './core/services/performance-error-tracking/error-tracking.service';
import { CampaignTrackingService } from './core/services/campaign-tracking/campaign-tracking.service';
import { UtilsService } from './core/services/utils/utils.service';
import { ChatService } from './core/services/chat/chat.service';
import { AnalyticsService } from './core/services/analytics/analytics.service';
import { LazyLoadHelperService } from './core/services/lazyLoadHelper/lazy-load-helper.service';
import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { SeoService } from './core/services/seo-service/seo.service';
import { environment } from '../environments/environment';
import { RichContentService } from './core/services/rich-content/rich-content.service';
import {
    LandingPagesUrlInitialRoute,
    MobileAppPagesUrlInitialRoute,
} from './modules/mobile-app-pages/constants/constants';
import { FullscreenService, IFullscreenConfig } from './shared/services/fullscreen-service/fullscreen.service';
import { Observable } from 'rxjs';
import { IUnsupportedCityProductModalConfig } from './shared/standalone-components/unsupported-product-modal/unsupported-city-product-modal.interface';
import { LocationService } from './shared/services/location/location.service';
import { StickyContainerComponent } from './shared/layouts/sticky-container/sticky-container.component';
import { StickyService } from './shared/services/sticky/sticky.service';
import { IStickySectionConfig } from './shared/interfaces/sticky-section.interface';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
// we should not write any logical code in this file
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild(StickyContainerComponent, { static: true }) stickyContainer: StickyContainerComponent;

    // Top notification, Header, footer and rich content are not needed
    isMobileAppStaticPage = false;
    isLandingPage = false;
    isCityInitialized = false;
    isFullscreen$: Observable<IFullscreenConfig> = this.fullscreenService.fullscreenDetail;
    unsupportedCityProductModalConfig: IUnsupportedCityProductModalConfig;
    showUnsupportedCityProductModal = false;
    stickySectionConfig: IStickySectionConfig;
    @ViewChild('commonRichData') private commonRichData: ElementRef;
    @ViewChild('appContainerElement') private appContainerElement: ElementRef;
    @ViewChild('appPrefixBanner') private appPrefixBanner: ElementRef;

    constructor(
        private seoService: SeoService,
        private lazyLoadService: LazyLoadHelperService,
        private analyticsService: AnalyticsService,
        private chatService: ChatService,
        private renderer2: Renderer2,
        private richContentService: RichContentService,
        private utilsService: UtilsService,
        private campaignTrackingService: CampaignTrackingService,
        private redirectManagerService: RedirectManagerService,
        private errorTrackingService: ErrorTrackingService,
        private selectCityService: SelectCityService,
        private fullscreenService: FullscreenService,
        private locationService: LocationService,
        private stickyService: StickyService
    ) {
        // This subscription checks whether the header, topNotification, footer needs to be shown to the user i.e no such component in case of mobile app pages
        this.utilsService.getRouterEvents$().subscribe(data => {
            this.isMobileAppStaticPage = data.urlAfterRedirects.startsWith(`/${MobileAppPagesUrlInitialRoute}`);
            this.isLandingPage = data.urlAfterRedirects.startsWith(`/${LandingPagesUrlInitialRoute}`);
            if (!this.isCityInitialized) {
                this.isCityInitialized = true;
                if (!this.isMobileAppStaticPage && !this.isLandingPage) {
                    // no city initialization for the mobile app pages and landing pages
                    this.selectCityService.setInitCity();
                }
            }
            // we are hitting this here bcz we want some specific work on some routes only
            // including lazy loading doesn't make sense bcz we already embedding this in last of body and async
            if (
                this.utilsService.getDeviceInfo().isBrowser &&
                (environment.production || environment.env === 'qa') &&
                (data.urlAfterRedirects.indexOf('flexi') > -1 || data.urlAfterRedirects.indexOf('meeting-room') > -1) &&
                !window.webengage
            ) {
                this.lazyLoadService.loadWebengage();
            }
        });
    }

    ngOnInit() {
        this.seoService.subscribeToRouterEvents();
        this.chatService.subscribeToRouterEvents();
        this.analyticsService.subscribeToRouterEvents();
        this.redirectManagerService.startRedirectManagerService();
        this.errorTrackingService.init();
        setTimeout(() => {
            this.campaignTrackingService.setCampaignCookies();
        });
        this.locationService.getLocationCoordinatesSub().subscribe(locationData => {
            if (locationData?.data && !locationData.data?.isSupported && locationData.product) {
                this.toggleUnsupportedCityProductModal(true);
                this.unsupportedCityProductModalConfig = {
                    product: locationData.product,
                    errorCode: locationData.data.errorCode,
                    city: locationData.data.location.city,
                };
            }
        });
    }

    ngAfterViewInit() {
        this.stickyService.setStickyContainer(this.stickyContainer);
        this.stickySectionConfig = {
            topElement: { ref: this.appContainerElement, resetPixel: 0 },
            containerRef: this.appContainerElement,
            classInfo: [{ containerRef: this.appPrefixBanner, className: '' }],
        };
        if (this.utilsService.getDeviceInfo().isBrowser) {
            if (environment.production) {
                this.lazyLoadService.loadFacebookPixel();
                // TODO Uncomment the next line when we will start using the hotjar service again
                // this.lazyLoadService.loadhotjar();
                // this.lazyLoadService.loadSegmentAnalytics();
                this.lazyLoadService.loadZohoChat();
            }
            if (environment.production || environment.env === 'qa') {
                this.lazyLoadService.loadMixpanelAnalytics();
                this.lazyLoadService.loadGTM();
                // We don't want webengage on every page so commenting this out and moving to specific pages / route
                // right now it is only for Flexi and MR
                // this.lazyLoadService.loadWebengage();
            }
        }
        // TODO :: we can think of appending this data directly to html and used variables
        // we have to move this logic from here
        this.richContentService.addRichData(this.renderer2, this.commonRichData);
    }

    toggleUnsupportedCityProductModal(show: boolean) {
        this.showUnsupportedCityProductModal = show;
    }

    closeUnsupportedCityProductModal() {
        this.toggleUnsupportedCityProductModal(false);
        this.locationService.clearLocationCoordinatesSubData();
    }
}
