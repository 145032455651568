import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IFieldConfig } from '../../interfaces/field.interface';
import { formFieldType } from '../../enums/enums';

@Directive({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formField]',
})
export class FormFieldDirective implements OnInit {
    @Input() field: IFieldConfig;
    @Input() form: UntypedFormGroup;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentRef: any;

    constructor(private container: ViewContainerRef) {}

    async ngOnInit() {
        if (Object.keys(this.field).length === 0) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let component: any;
        switch (this.field.fieldType) {
            case formFieldType.INPUT:
            case formFieldType.PASSWORD:
                component = (await import('../../ui-components/input/input.component')).InputComponent;
                break;
            case formFieldType.DROPDOWN:
                component = (await import('../../ui-components/dropdown/dropdown.component')).DropdownComponent;
                break;
            case formFieldType.MULTI_DROPDOWN:
                component = (await import('../../ui-components/multi-select-dropdown/multi-select-dropdown.component'))
                    .MultiSelectDropdownComponent;
                break;
            case formFieldType.SINGLE_SELECT_DROPDOWN:
                component = (
                    await import('../../ui-components/single-select-dropdown/single-select-dropdown.component')
                ).SingleSelectDropdownComponent;
                break;
            case formFieldType.TEXTAREA:
                component = (await import('../../ui-components/textarea/textarea.component')).TextareaComponent;
                break;
            case formFieldType.DATE:
                component = (await import('../../ui-components/calendar-form-field/calendar-form-field.component'))
                    .CalendarFormFieldComponent;
                break;
            case formFieldType.TIME_DURATION:
                component = (
                    await import('../../ui-components/time-duration-form-field/time-duration-form-field.component')
                ).TimeDurationFormFieldComponent;
                break;
            default:
                component = (await import('../../ui-components/input/input.component')).InputComponent;
        }

        this.componentRef = this.container.createComponent(component);
        this.componentRef.instance.field = this.field;
        this.componentRef.instance.form = this.field.optional ? this.form.get('optionalFields') : this.form;
    }
}
