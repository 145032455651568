import { CommonIconsUrl } from '../../core/constants/common-icons-url';
import { MarketingTags } from '../enums/enums';
import { IMarketingTagMap } from '../interfaces/workspace.interface';
import { IInfoStepsSectionConfig } from '../standalone-components/info-steps-section/info-steps-section.interface';

export const ZoomLocationMap = {
    REGION: 11,
    CITY: 10,
    LOCALITY: 11,
    DEFAULT: 10,
    WORKSPACE: 13,
    NEIGHBOURHOOD: 15,
};

export const ListingPageSize = {
    mobileView: 16,
    mapView: 16,
    listView: 32,
};

export const WorkspacePopularityScoreThreshold = 6;

export const whyMyHqCreditsInfoConfig: IInfoStepsSectionConfig = {
    heading: 'What are myHQ credits?',
    backgroundConfig: { transparent: false },
    paddingSmall: true,
    steps: [
        'Every workspace you want to book on myHQ is priced in credits.',
        'Credit conversion on myHQ is 1 credit = ₹1.5. However every booking at myHQ comes with guaranteed discounts. Book to know more.',
    ],
};

export const listingMarketingTagsMap: IMarketingTagMap = {
    [MarketingTags.BudgetFriendly]: {
        icon: CommonIconsUrl.BUDGET,
        label: 'Budget Friendly',
        class: 'budget-tag',
    },
    [MarketingTags.PremiumAddress]: {
        icon: CommonIconsUrl.PREMIUM,
        label: 'Premium Address',
        class: 'premium-address',
    },
};

export const INVOICE_POLLING_DURATION = 45000; // 45 secs
export const INVOICE_POLLING_INTERVAL = 5000; // 5 secs

export const TYPEHEAD_WAIT_TIME = 300; // 0.3 secs

export const GST_REGISTRATION_GUIDE_PDF_LINK =
    'https://myhq.s3.ap-south-1.amazonaws.com/static/virtual_office/GST+Registration.pdf';

export const COMPANY_REGISTRATION_GUIDE_PDF_LINK =
    'https://myhq.s3.ap-south-1.amazonaws.com/static/virtual_office/Company+Registration.pdf';
