export enum MeetingRoomEquipmentTagSize {
    LARGE = 'LARGE',
    SMALL = 'SMALL',
}

export enum MeetingRoomEquipmentVariant {
    DEFAULT = 'DEFAULT',
    PRIMARY_ICON = 'PRIMARY_ICON',
}

export enum MeetingRoomSlotStatus {
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE',
    SELECTED = 'SELECTED',
}

export enum MeetingRoomStatus {
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE',
    CLOSED = 'CLOSED',
    SOLD_OUT = 'SOLD_OUT',
}

export enum MeetingRoomReservationErrorTypes {
    INVENTORY = 'INVENTORY',
    TEAM_POLICY = 'TEAM_POLICY',
    CORPORATE_ACCOUNT = 'CORPORATE_ACCOUNT',
}

export enum MeetingRoomReservationErrorSubTypes {
    SOLD_OUT = 'SOLD_OUT',
    UNAVAILABLE = 'UNAVAILABLE',
    PRODUCT_NOT_ALLOWED = 'PRODUCT_NOT_ALLOWED',
    LOW_BALANCE = 'LOW_BALANCE',
    PAST_DATE = 'PAST_DATE',
}

export enum MeetingRoomUserWalletType {
    INDIVIDUAL = 'INDIVIDUAL',
    CORPORATE = 'CORPORATE',
}

export enum MeetingRoomBookingStatus {
    CREATED = 'CREATED',
    FAILED = 'FAILED',
    BOOKED = 'BOOKED',
    VISITED = 'VISITED',
    NOSHOW = 'NOSHOW',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
}

export enum MeetingRoomInventoryState {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum MeetingRoomInventorySubState {
    TEMP_TECH_DISRUPTION = 'TEMP_TECH_DISRUPTION',
}

export enum MeetingRoomProviderId {
    RAZORPAY = 'RAZORPAY',
    ZERO_AMT_TX = 'ZERO_AMT_TX',
}
