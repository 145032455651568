import { Injectable } from '@angular/core';
import { ElementRef } from '@angular/core';
import { StickyContainerComponent } from '../../layouts/sticky-container/sticky-container.component';

@Injectable({
    providedIn: 'root',
})
export class StickyService {
    private stickyContainerComponent: StickyContainerComponent;

    setStickyContainer(container: StickyContainerComponent) {
        this.stickyContainerComponent = container;
    }

    getStickyContainerRef() {
        return this.stickyContainerComponent.stickyContainer;
    }

    addElementToSticky(element: ElementRef, containerRef: ElementRef, className?: string) {
        if (this.stickyContainerComponent) {
            this.stickyContainerComponent.addElement(element, containerRef, className);
        }
    }

    removeElement(element: ElementRef, containerRef: ElementRef, className?: string) {
        if (this.stickyContainerComponent) {
            this.stickyContainerComponent.removeElement(element, containerRef, className);
        }
    }

    clearStickyElements() {
        if (this.stickyContainerComponent) {
            this.stickyContainerComponent.clearElements();
        }
    }
}
