import { knowledgeCenterData } from '../knowledge-center-data';

export function getMasterCategories(): string[] {
    return Object.keys(knowledgeCenterData);
}

export function getSubCategories(masterCategory: string): string[] {
    const categoryData = knowledgeCenterData[masterCategory];
    if (!categoryData) {
        throw new Error(`Master category '${masterCategory}' not found.`);
    }
    return Object.keys(categoryData)?.filter(key => key !== 'title' && key !== 'content');
}
