import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonIconsUrl } from '../../../../../core/constants/common-icons-url';
import { IAuthDropdownItem } from '../../common-header.interface';
import { menuEnum, menuType } from '../../enums/enums';
import { buttonType, fontSizes } from '../../../../enums/enums';
import { Subscription } from 'rxjs';
import { IButtonConfig } from '../../../../interfaces/button.interface';
import { UtilsService } from '../../../../../core/services/utils/utils.service';
import { AuthStatus } from '../../../../../modules/auth/auth.enum';
import { MobileOtpAuthService } from '../../../../services/auth/mobile-otp-auth.service';
import { IMobileOtpUserInfoData } from '../../../../../modules/mobile-otp-auth/mobile-otp-auth.interface';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { ButtonsModule } from '../../../../ui-components/buttons/buttons.module';
import { DirectivesModule } from '../../../../directives/directives.module';

@Component({
    selector: 'app-login-signup-flexi-menu-item',
    templateUrl: './login-signup-flexi-menu-item.component.html',
    styleUrls: ['./login-signup-flexi-menu-item.component.scss'],
    standalone: true,
    imports: [ButtonsModule, DirectivesModule, NgTemplateOutlet, NgClass],
})
export class LoginSignupFlexiMenuItemComponent implements OnInit, OnDestroy {
    deviceInfo = this.utilsService.getDeviceInfo();
    dropdownLabel = 'Login / Sign up';
    userData: IMobileOtpUserInfoData;
    isLoggedIn = false;
    CommonIconsUrl = CommonIconsUrl;
    showDropdown = false;

    logoutDropdownItem: IAuthDropdownItem = {
        id: menuEnum.LOGOUT,
        type: menuType.LINK,
        label: 'Log out',
        show: true,
        icon: {
            url: CommonIconsUrl.LOGOUT,
            alt: 'Log out',
        },
        comingSoon: false,
        onClick: () => {
            this.handleLogout();
        },
        cssClass: 'danger',
    };
    loginBtnConfig: IButtonConfig = {
        label: 'Login / Sign Up',
        type: buttonType.PRIMARY,
        size: fontSizes.MEDIUM_MOB_DESK,
    };
    selectCitySub: Subscription;
    authStatusSub: Subscription;
    authUserDetailsSub: Subscription;

    constructor(
        private utilsService: UtilsService,
        private authService: MobileOtpAuthService
    ) {}

    ngOnInit(): void {
        this.authStatusSub = this.authService.getAuthStatusSub().subscribe(authStatus => {
            this.isLoggedIn = authStatus === AuthStatus.AUTHENTICATED;
            this.setDropdownLabel();
        });
        this.authUserDetailsSub = this.authService.getUserDataSub().subscribe(userData => {
            this.userData = userData;
            this.setDropdownLabel();
        });
    }

    ngOnDestroy() {
        if (this.authStatusSub) {
            this.authStatusSub.unsubscribe();
        }
        if (this.authUserDetailsSub) {
            this.authUserDetailsSub.unsubscribe();
        }
    }

    toggleDropdown(show: boolean): void {
        this.showDropdown = show;
    }

    handleLogout() {
        this.authService.logout().subscribe(success => {
            if (success) {
                this.authService.setUserData(null);
                this.authService.setAuthStatus(AuthStatus.UNAUTHENTICATED);
            }
        });
        this.toggleDropdown(false);
    }

    setDropdownLabel() {
        if (this.isLoggedIn && this.userData) {
            const { user } = this.userData;

            if (user?.name) {
                this.dropdownLabel = `Hi ${user.name}`;
            } else {
                this.dropdownLabel = 'My Account';
            }
        } else {
            this.dropdownLabel = 'Login / Sign up';
        }
    }

    handleMobileLoginCTA() {
        if (!this.isLoggedIn) {
            this.handleLoginCTA();
        } else {
            this.handleLogout();
        }
    }

    handleLoginCTA() {
        this.authService.toggleLoginModal(true);
    }
}
