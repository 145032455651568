import { Injectable, NgZone } from '@angular/core';
import { zip, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObserverQueue } from '../../../classes/observerQueue.class';

const queue = new ObserverQueue();

@Injectable({
    providedIn: 'root',
})
export class GoogleTagAnalyticsService {
    pagePathGroupConfig = [
        {
            pageUrlRegex: [/^\/lp\/[^\/]+\/coworking-space$/, /^\/lp\/[^\/]+\/coworking-space-2$/],
            additionalInfo: { page_group: 'lp-cs' },
        },
        {
            pageUrlRegex: [/^\/lp\/[^\/]+\/virtual-office$/, /^\/lp\/virtual-office$/],
            additionalInfo: { page_group: 'lp-vo' },
        },
        {
            pageUrlRegex: [/^\/lp\/meeting-rooms$/],
            additionalInfo: { page_group: 'lp-mr' },
        },
        {
            pageUrlRegex: [/^\/lp\/[^\/]+\/managed-office$/],
            additionalInfo: { page_group: 'lp-mo' },
        },
        {
            pageUrlRegex: [/^\/lp\/[^\/]+\/office-space$/, /^\/lp\/[^\/]+\/office-space-2$/],
            additionalInfo: { page_group: 'lp-os' },
        },
        {
            pageUrlRegex: [/^\/lp\/[^\/]+\/enterprises$/, /^\/lp\/enterprises$/],
            additionalInfo: { page_group: 'lp-enterprises' },
        },
        {
            pageUrlRegex: [/^\/coworking-space(\/?.*)?$/],
            additionalInfo: { page_group: 'generic-space-detail' },
        },
        {
            pageUrlRegex: [/^\/mp(\/?.*)?$/],
            additionalInfo: { page_group: 'app-static-pages' },
        },
        {
            pageUrlRegex: [/^\/sp(\/?.*)?$/],
            additionalInfo: { page_group: 'standalone-pages' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/flexi(\/?)?$/],
            additionalInfo: { page_group: 'flexi-product' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/flexi\/plans(\/?)?$/],
            additionalInfo: { page_group: 'flexi-plans' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/flexi\/coworking-space-in-(.*)?$/, /^\/[^\/]+\/flexi\/work-cafes-in-(.*)?$/],
            additionalInfo: { page_group: 'flexi-listing' },
        },
        {
            pageUrlRegex: [/^\/flexi\/referral\/invite(\/?)?$/],
            additionalInfo: { page_group: 'flexi-referral-invite' },
        },
        {
            pageUrlRegex: [/^\/flexi\/coworking-space(\/?.*)?$/],
            additionalInfo: { page_group: 'flexi-space-detail' },
        },
        {
            pageUrlRegex: [/^\/flexi\/book\/select(\/?.*)?$/],
            additionalInfo: { page_group: 'flexi-space-detail' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/dedicated(\/?)?$/],
            additionalInfo: { page_group: 'dedicated-product' },
        },
        {
            pageUrlRegex: [
                /^\/[^\/]+\/dedicated\/coworking-space-in-(.*)?$/,
                /^\/[^\/]+\/dedicated\/dedicated-desks-in-(.*)?$/,
                /^\/[^\/]+\/dedicated\/private-cabins-in-(.*)?$/,
            ], // dedicated listing regex
            additionalInfo: { page_group: 'dedicated-listing' },
        },
        {
            pageUrlRegex: [/^\/dedicated\/coworking-space(\/?.*)?$/],
            additionalInfo: { page_group: 'dedicated-space-detail' },
        },
        {
            pageUrlRegex: [/^\/dedicated\/visit\/success(\/?.*)?$/],
            additionalInfo: { page_group: 'dedicated-visit-success' },
        },
        {
            pageUrlRegex: [/^\/dedicated\/schedule-visit(\/?.*)?$/],
            additionalInfo: { page_group: 'dedicated-schedule-visit' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/virtual-office(\/?)?$/],
            additionalInfo: { page_group: 'vo-product' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/virtual-office\/virtual-office-in-(.*)?$/],
            additionalInfo: { page_group: 'vo-listing' },
        },
        {
            pageUrlRegex: [
                /^\/virtual-office\/payments+\/[^\/]+\/checkout(\/?.*)?$/,
                /^\/virtual-office\/payments+\/[^\/]+\/edit-checkout(\/?.*)?$/,
            ],
            additionalInfo: { page_group: 'vo-payments-checkout' },
        },
        {
            pageUrlRegex: [/^\/virtual-office\/payments+\/[^\/]+\/confirm(\/?.*)?$/],
            additionalInfo: { page_group: 'vo-payments-confirm' },
        },
        {
            pageUrlRegex: [/^\/virtual-office\/payments\/success(\/?.*)?$/],
            additionalInfo: { page_group: 'vo-payments-success' },
        },
        {
            pageUrlRegex: [/^\/virtual-office\/(.*)?$/],
            additionalInfo: { page_group: 'vo-space-detail' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/meeting-room\/(.*)?$/],
            additionalInfo: { page_group: 'mr-listing' },
        },
        {
            pageUrlRegex: [/^\/meeting-room\/payment\/room-list(\/?.*)?$/],
            additionalInfo: { page_group: 'mr-room-list' },
        },
        {
            pageUrlRegex: [/^\/meeting-room\/payment\/checkout+\/[^\/]+\/cart(\/?.*)?$/],
            additionalInfo: { page_group: 'mr-payment-checkout-cart' },
        },
        {
            pageUrlRegex: [/^\/meeting-room\/payment\/checkout+\/[^\/]+\/add-credits(\/?.*)?$/],
            additionalInfo: { page_group: 'mr-payment-checkout-add-credits' },
        },
        {
            pageUrlRegex: [/^\/meeting-room\/payment\/checkout(\/?.*)?$/],
            additionalInfo: { page_group: 'mr-payment-checkout' },
        },
        {
            pageUrlRegex: [/^\/meeting-room\/payment\/confirm(\/?.*)?$/],
            additionalInfo: { page_group: 'mr-payment-confirm' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/corporate-flexi(\/?)?$/],
            additionalInfo: { page_group: 'corporate-flexi-product' },
        },
        {
            pageUrlRegex: [/^\/[^\/]+\/brand(\/?.*)?$/],
            additionalInfo: { page_group: 'brand-listing' },
        },
        {
            pageUrlRegex: [/^\/about-us(\/?)?$/],
            additionalInfo: { page_group: 'about-us' },
        },
        {
            pageUrlRegex: [/^\/termsncondition(\/?)?$/],
            additionalInfo: { page_group: 'terms-and-condition' },
        },
        {
            pageUrlRegex: [/^\/privacy(\/?)?$/],
            additionalInfo: { page_group: 'privacy' },
        },
        {
            pageUrlRegex: [/^\/refund(\/?)?$/],
            additionalInfo: { page_group: 'refund' },
        },
        {
            pageUrlRegex: [/^\/faq(\/?)?$/],
            additionalInfo: { page_group: 'faq' },
        },
        {
            pageUrlRegex: [/^\/community-benefits(\/?)?$/],
            additionalInfo: { page_group: 'community-benefits' },
        },
        {
            pageUrlRegex: [/^\/contact(\/?)?$/],
            additionalInfo: { page_group: 'contact' },
        },
        {
            pageUrlRegex: [/^\/app(\/?)?$/],
            additionalInfo: { page_group: 'app-redirect' },
        },
        {
            pageUrlRegex: [/^\/app-download(\/?)?$/],
            additionalInfo: { page_group: 'app-download' },
        },
        {
            pageUrlRegex: [/^\/shortlisted-workspaces(\/?)?$/],
            additionalInfo: { page_group: 'dedicated-shortlist' },
        },
        {
            pageUrlRegex: [/^\/list-space(\/?)?$/],
            additionalInfo: { page_group: 'list-space' },
        },
        {
            pageUrlRegex: [/^\/login(\/?)?$/],
            additionalInfo: { page_group: 'login' },
        },
        {
            pageUrlRegex: [/^\/rate-your-experience(\/?.*)?$/],
            additionalInfo: { page_group: 'app-booking-rate' },
        },
        {
            pageUrlRegex: [/^\/office-space-for-enterprises(\/?)?$/],
            additionalInfo: { page_group: 'dedicated-enterprise' },
        },
        {
            pageUrlRegex: [/^\/profile\/verify-email(\/?)?$/],
            additionalInfo: { page_group: 'verify-email' },
        },
    ];

    constructor(private ngZone: NgZone) {}

    init() {
        window['googleTagAnalyticsService'] = {
            component: this,
            zone: this.ngZone,
            onScriptLoaded: () => this.onScriptLoaded(),
        };
        window['dataLayer'] = window['dataLayer'] || [];
    }

    onScriptLoaded() {
        zip(queue, interval(10))
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .pipe(map(([queueItem, d]) => queueItem))
            .subscribe(({ event, resolve }) => {
                resolve(event());
            });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackEvent(event: string, properties: object): any {
        const gtagProperties = { event, ...properties };
        window.dataLayer.push(gtagProperties);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackPageNavigation(properties): any {
        const additionalInfo = this.getPageGroup(properties.url);
        const gtagProperties = { event: 'PageView', ...additionalInfo, ...properties };
        window.dataLayer.push(gtagProperties);
    }

    getPageGroup(url?: string) {
        if (url) {
            const urlPath = url.split('?')[0];
            // In the below line we are passing array and checking if url is based on regex which result an array of pagePathGroupConfig object
            const pageObj = this.pagePathGroupConfig.filter(pageObj =>
                pageObj?.pageUrlRegex.find(urlRegex => urlRegex?.test(urlPath))
            );
            return pageObj[0]?.additionalInfo || { page_group: 'home-page' };
        }
    }
}
