import { buttonHTMLType, buttonType, fontSizes } from '../enums/enums';
import { ILoaderConfig } from '../ui-components/loaders/loaders.component';

export enum ButtonIconPosition {
    LEFT = 'left',
    RIGHT = 'right',
}
export interface IButtonConfig {
    id?: string;
    type: buttonType;
    size?: fontSizes;
    fontWeight?: string; // NORMAL (default), BOLD
    label: string;
    subLabel?: string;
    cssClass?: string;
    icon?: string;
    iconUrl?: string;
    showLoader?: boolean;
    loaderConfig?: ILoaderConfig;
    iconPosition?: string;
    disabled?: boolean;
    hidden?: boolean;
    htmlType?: buttonHTMLType;
}
