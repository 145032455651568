@if (cmsMetaDataConfig?.length) {
    <div class="app-container section-container cms-metadata-container">
        <app-footer-cms-metadata [config]="cmsMetaDataConfig"></app-footer-cms-metadata>
    </div>
}
@if (showFooter) {
    <footer>
        @if (showPrimaryFooter) {
            <div class="primary-footer">
                <div class="app-container">
                    <div class="footer d-flx">
                        <div class="products d-flx">
                            @for (footerItem of primaryFooterLinks$ | async; track footerItem) {
                                <div class="section flx-1">
                                    @if (footerItem.url) {
                                        <div class="logo-cont">
                                            <a [routerLink]="homepageUrl | async" aria-label="myhq">
                                                <img
                                                    appImageShimmerLazyLoad
                                                    class="icon image-cover object-fit-contain"
                                                    [imageSrc]="footerItem.url"
                                                    alt="myhq"
                                                />
                                            </a>
                                        </div>
                                    }
                                    @if (footerItem.title) {
                                        <div class="title-cont">
                                            <h3 class="title">{{ footerItem.title }}</h3>
                                        </div>
                                    }
                                    <div class="list d-flx">
                                        @for (item of footerItem.items; track item) {
                                            <div class="d-flx a-items-c item-container">
                                                @if (item.isExternal) {
                                                    <a
                                                        class="item"
                                                        aria-label="{{ item.label }}"
                                                        href="{{ item.url | async }}"
                                                        target="_blank"
                                                        >{{ item.label }}</a
                                                    >
                                                }
                                                @if (!item.isExternal) {
                                                    <a
                                                        class="item"
                                                        aria-label="{{ item.label }}"
                                                        [routerLink]="item.url | async"
                                                        >{{ item.label }}</a
                                                    >
                                                }
                                                @if (item.tag) {
                                                    <div class="tag d-flx a-items-c">{{ item.tag }}</div>
                                                }
                                                @if (item.comingSoon) {
                                                    <div class="tag-grey d-flx a-items-c">
                                                        <i>- Coming soon</i>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="communication">
                            <div class="show-desk">
                                @if (!successResponse.success) {
                                    <div>
                                        <div class="title-cont"><h3 class="title">Join the newsletter</h3></div>
                                        <app-input-with-button
                                            [fieldObj]="formConfig"
                                            (submit)="subscribeNewsletter($event)"
                                        ></app-input-with-button>
                                    </div>
                                }
                                @if (successResponse.success) {
                                    <div class="success-msg">
                                        <app-lead-success-portrait
                                            [config]="successResponse.successConfig"
                                        ></app-lead-success-portrait>
                                    </div>
                                }
                                @if (errorResponse.visible) {
                                    <div class="error-msg">{{ errorResponse.content }}</div>
                                }
                            </div>
                            <div>
                                <div class="title-cont">
                                    <h3 class="title">Feel free to connect with us</h3>
                                </div>
                                <div class="contacts d-flx">
                                    @for (contactButton of contactButtons; track contactButton) {
                                        <div class="contact d-flx a-items-c">
                                            <div class="contact-icon-cont d-flx a-items-c">
                                                <div class="svg-cont d-flx" [innerHTML]="contactButton.icon"></div>
                                            </div>
                                            <a
                                                class="text"
                                                aria-label="{{ contactButton.label }}"
                                                [href]="contactButton.link"
                                                >{{ contactButton.label }}</a
                                            >
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="external-links d-flx j-cont-space-bet">
                        <div class="d-flx a-items-c">
                            <div class="text">Keep in touch</div>
                            @for (item of externalLinks.socialMedia; track item) {
                                <div>
                                    <a href="{{ item.url }}" target="_blank" aria-label="myhq">
                                        <div
                                            class="d-flx contact-link-icon-cont svg-cont"
                                            [innerHTML]="item.icon"
                                        ></div>
                                    </a>
                                </div>
                            }
                        </div>
                        <div class="d-flx flx-d-row a-items-c anarock-logo-container">
                            <div [innerHTML]="boltIcon" class="d-flx logo a-items-c j-cont-cen"></div>
                            <p class="label">Powered by</p>
                            <img
                                [height]="22"
                                [src]="anarockLogo"
                                alt="anarock"
                                [imageWidthSize]="'largethumbnail'"
                                appImageProgressiveLazyLoad
                                class="anarock-logo object-fit-contain c-pointer"
                                (click)="handleAnarockLogoClick()"
                            />
                        </div>
                        <div class="show-desk">
                            <div class="d-flx a-items-c">
                                <div class="text">Download myHQ App</div>
                                @for (item of externalLinks.appDowload; track item) {
                                    <div class="app-download-link-cont">
                                        <a
                                            href="{{ item.url }}"
                                            target="_blank"
                                            (click)="trackAppDownloadAnalytics(item.redirectType)"
                                            aria-label="myhq"
                                        >
                                            <div class="app-download-image-cont">
                                                <img
                                                    appImageShimmerLazyLoad
                                                    [width]="108"
                                                    [height]="32"
                                                    class="image"
                                                    [imageSrc]="item.imageUrl"
                                                    [alt]="item.name"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        @if (secondaryFooterLinks$ | async; as secondaryFooterLinks) {
            @if (secondaryFooterLinks.productCategories?.length) {
                <div class="secondary-footer" [ngClass]="{ 'primary-footer-colour': !showPrimaryFooter }">
                    <div class="app-container">
                        <!--              hide the product links if disabled -- only show about myHQ in such cases-->
                        @if (showProductCategoryLinks) {
                            <div class="popular-workspaces">
                                @for (footerItem of secondaryFooterLinks.productCategories; track footerItem) {
                                    @if (footerItem.links.length) {
                                        <div class="workspace">
                                            <h2 class="title">{{ footerItem.label }}</h2>
                                            <div class="list">
                                                <ul class="list-horizontal">
                                                    @for (item of footerItem.links; track item; let i = $index) {
                                                        <li>
                                                            <a
                                                                class="item"
                                                                aria-label="{{ item.label }}"
                                                                [routerLink]="item.redirectUrl"
                                                                >{{ item.label }}</a
                                                            >
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                }
                            </div>
                        }
                        <div class="about-myhq">
                            <div class="logo-cont">
                                <a [routerLink]="homepageUrl | async" aria-label="myhq">
                                    <img
                                        appImageShimmerLazyLoad
                                        class="image-cover"
                                        [imageSrc]="commonIconsUrl.LOGO_LARGE"
                                        alt="myhq"
                                    />
                                </a>
                            </div>
                            <p class="text">
                                myHQ provides affordable, hyperlocal and flexible office space solutions for businesses
                                and individuals. Choose between pay-per-use plans or fixed desks.
                            </p>
                        </div>
                        <hr />
                        <div class="copyright">
                            Copyright &copy; {{ currentYear }}, Upflex Anarock India Private Limited. All Rights
                            Reserved.
                        </div>
                    </div>
                </div>
            }
        }
    </footer>
}
