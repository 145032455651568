import { EventEmitter, Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { AuthStatus, LoginFormStages } from '../../../modules/mobile-otp-auth/mobile-otp-auth.enum';
import {
    IGenerateOtpData,
    IGenerateOtpPayload,
    IMobileOtpUserInfoData,
    ISignupPayload,
    IUserDefaultAccount,
    IVerifyOtpData,
    IVerifyOtpPayload,
} from '../../../modules/mobile-otp-auth/mobile-otp-auth.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IHttpResponse } from '../../interfaces/HTTPResponse.interface';
import { catchError, map } from 'rxjs/operators';
import { CustomHttpParams } from '../../../core/interceptors/custom-params.interceptor';
import { InterceptorMetadataKeys, productsMap } from '../../enums/enums';
import { ErrorService } from '../../../core/services/error/error.service';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';

@Injectable({
    providedIn: 'root',
})
export class MobileOtpAuthService {
    private loginModalStage: BehaviorSubject<LoginFormStages> = new BehaviorSubject<LoginFormStages>(
        LoginFormStages.ENTER_MOBILE
    );
    private loginModalViewState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private authStatus: BehaviorSubject<AuthStatus> = new BehaviorSubject<AuthStatus>(null);
    private userData$: BehaviorSubject<IMobileOtpUserInfoData> = new BehaviorSubject<IMobileOtpUserInfoData>(null);
    private userData: IMobileOtpUserInfoData;
    public userLoggedOut = new EventEmitter();
    public userLoggedIn = new EventEmitter<IMobileOtpUserInfoData['user']>();

    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private analyticsService: AnalyticsService
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    signup = (payload: ISignupPayload): Observable<IHttpResponse<any>> => {
        const url = `${environment.baseUrl}/auth/web/flexi-signup`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.post<IHttpResponse<any>>(url, payload).pipe(
            map(res => {
                if (res?.success) {
                    this.updateUserInfo(true);
                }
                return res;
            }),
            catchError(this.errorService.handleError)
        );
    };

    generateOtp = (payload: IGenerateOtpPayload): Observable<IHttpResponse<IGenerateOtpData>> => {
        const url = `${environment.baseUrl}/auth/web/flexi-otp`;
        return this.http.post<IHttpResponse<IGenerateOtpData>>(url, payload).pipe(
            map(res => {
                return res;
            }),
            catchError(this.errorService.handleError)
        );
    };

    verifyOtp = (payload: IVerifyOtpPayload): Observable<IHttpResponse<IVerifyOtpData>> => {
        const url = `${environment.baseUrl}/auth/web/flexi-verify`;
        return this.http.post<IHttpResponse<IVerifyOtpData>>(url, payload).pipe(
            map(res => {
                if (res.success && res.data.isOtpVerified && !res.data.isNew) {
                    this.updateUserInfo(true);
                }
                return res;
            }),
            catchError(this.errorService.handleError)
        );
    };

    logout = () => {
        const url = `${environment.baseUrl}/auth/web/flexi-logout`;
        const customHttpParams = new CustomHttpParams({
            interceptorMetadata: {
                [InterceptorMetadataKeys.HQ_DISABLE_CACHE]: true,
            },
        });
        const httpOptions = {
            params: customHttpParams,
        };
        return this.http.post<IHttpResponse<void>>(url, {}, httpOptions).pipe(
            map(res => {
                if (res.success) {
                    this.analyticsService.resetOnLogout();
                    this.userLoggedOut.emit();
                }
                return res?.success;
            })
        );
    };

    verifyUser = (): Observable<IMobileOtpUserInfoData> => {
        if (this.userData) {
            return of(this.userData);
        } else {
            const url = `${environment.baseUrl}/auth/web/flexi-verify`;
            const customHttpParams = new CustomHttpParams({
                interceptorMetadata: {
                    [InterceptorMetadataKeys.HQ_DISABLE_CACHE]: true,
                },
            });
            const httpOptions = {
                params: customHttpParams,
            };
            return this.http.get<IHttpResponse<IMobileOtpUserInfoData>>(url, httpOptions).pipe(
                map(res => {
                    if (res?.success) {
                        return res.data;
                    }
                }),
                catchError(this.errorService.handleError)
            );
        }
    };

    getUserDefaultAccount() {
        const url = `${environment.baseUrl}/users/web/default-account`;
        return this.http.get<IHttpResponse<IUserDefaultAccount>>(url).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    switchUserDefaultAccount(defaultAccount: string) {
        const url = `${environment.baseUrl}/users/web/default-account`;
        return this.http.put<IHttpResponse<IUserDefaultAccount>>(url, { defaultAccount }).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                }
            }),
            catchError(this.errorService.handleError)
        );
    }

    updateUserInfo(emitLoggedIn: boolean) {
        this.verifyUser().subscribe(userInfo => {
            if (emitLoggedIn) {
                this.userLoggedIn.emit(userInfo.user);
            }
            if (userInfo) {
                this.setUserData(userInfo);
                if (userInfo.isAuthenticated) {
                    this.setAuthStatus(AuthStatus.AUTHENTICATED);
                } else {
                    this.setUserData(null);
                    this.setAuthStatus(AuthStatus.UNAUTHENTICATED);
                }
                if (!userInfo.isAuthenticated && userInfo.isOtpVerified) {
                    this.toggleLoginModal(true, LoginFormStages.CREATE_PROFILE);
                }
            }
        });
    }

    toggleLoginModal = (show: boolean, formStage?: LoginFormStages) => {
        if (show && (!formStage || formStage === LoginFormStages.ENTER_MOBILE)) {
            this.analyticsService.trackMobileOtpLoginPopViewed({
                product: productsMap.MEETING_ROOM.productNameBackend,
            });
        }
        this.loginModalViewState.next(show);
        if (formStage) {
            this.updateLoginFormStage(formStage);
        } else {
            // If not form stage is passed, then open enter mobile stage by default
            this.updateLoginFormStage(LoginFormStages.ENTER_MOBILE);
        }
    };
    getLoginViewStateSub = (): Observable<boolean> => {
        return this.loginModalViewState;
    };

    updateLoginFormStage = (stage: LoginFormStages) => {
        this.loginModalStage.next(stage);
    };
    getLoginModalStageSub = (): Observable<LoginFormStages> => {
        return this.loginModalStage;
    };

    setAuthStatus = (status: AuthStatus) => {
        this.authStatus.next(status);
    };
    getAuthStatusSub = (): Observable<AuthStatus> => {
        return this.authStatus;
    };

    setUserData = (data?: IMobileOtpUserInfoData) => {
        this.userData = data;
        this.userData$.next(data);
        if (data?.user) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { _id, ...rest } = data.user;
            this.analyticsService.identifyUser(rest?.phone, { ...rest, accountType: rest.defaultAccount });
        }
    };

    getUserDataSub = (): Observable<IMobileOtpUserInfoData> => {
        return this.userData$;
    };

    getUserLoggedOut() {
        return this.userLoggedOut;
    }

    getUserLoggedIn() {
        return this.userLoggedIn;
    }
}
