import { WebengageService } from './../analytics/analytics-api/webengage.service';
import { FacebookAnalyticsService } from '../analytics/analytics-api/facebook-analytics.service';
import { MixPanelService } from '../analytics/analytics-api/mix-panel.service';
import { UtilsService } from './../utils/utils.service';
import { SegmentAnalyticsService } from '../analytics/analytics-api/segment-analytics.service';
import { GoogleTagAnalyticsService } from '../analytics/analytics-api/google-tag-analytics.service';
import { ChatService } from '../chat/chat.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ScriptLoadStrategy } from '../../../shared/enums/enums';
import { from, map, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LazyLoadHelperService {
    private loadScriptFromLink(
        src: string,
        loadingStrategy: ScriptLoadStrategy = ScriptLoadStrategy.ASYNC,
        onLoadCallback?: () => void
    ): HTMLScriptElement | null {
        if (this.utilsService.getDeviceInfo().isBrowser) {
            const script = document.createElement('script');
            {
                script.onload = onLoadCallback ? onLoadCallback : null;
                script.src = src;
                script.type = 'text/javascript';
                script.async = loadingStrategy === ScriptLoadStrategy.ASYNC;
                script.defer = loadingStrategy === ScriptLoadStrategy.DEFER;
                document.getElementsByTagName('head')[0].appendChild(script);
            }
            return script;
        }
        return null;
    }

    loadScriptFromCode(innerHTML: string, id?: string) {
        if (this.utilsService.getDeviceInfo().isBrowser) {
            const script = document.createElement('script');
            if (id) {
                script.setAttribute('id', id);
            }
            script.innerHTML = innerHTML;
            script.type = 'text/javascript';
            // loading strategy already present in the respective script
            document.body.appendChild(script);
        }
    }

    private loadNoScript(innerHTML: string) {
        const noscript = document.createElement('noscript');
        noscript.innerHTML = innerHTML;
        document.getElementsByTagName('body')[0].appendChild(noscript);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private loadStyle(src: string, options?: any, onload?: () => void) {
        if (!this.utilsService.getDeviceInfo().isBrowser) {
            return null;
        }

        const css = document.createElement('link');
        css.onload = onload ? onload : null;
        css.rel = 'stylesheet';
        css.as = 'style';
        css.href = src;
        css.charset = 'utf-8';
        if (options) {
            for (const key in options) {
                css.setAttribute(key, options[key]);
            }
        }
        document.head.insertBefore(css, document.head.childNodes[document.head.childNodes.length - 1].nextSibling);
    }

    private async loadSlickCarousel() {
        const loadStylePromise = new Promise<void>(resolve => {
            this.loadStyle('app-carousel.css', null, () => resolve());
        });

        const loadScriptPromise = new Promise<void>(resolve => {
            this.loadScriptFromLink('app-carousel.js', ScriptLoadStrategy.ASYNC, () => resolve());
        });

        await Promise.all([loadStylePromise, loadScriptPromise]);
        return;
    }

    /**
     * Only load the slick carousel resources once
     */
    readonly loadSlickCarousel$ = from(this.loadSlickCarousel()).pipe(
        map(() => true),
        shareReplay(1)
    );

    loadCloudinaryVideoPlayerStyles() {
        this.loadStyle(
            'https://cdnjs.cloudflare.com/ajax/libs/cloudinary-video-player/2.0.5/cld-video-player.min.css',
            {
                crossOrigin: 'anonymous',
                referrerPolicy: 'no-referrer',
            }
        );
    }

    loadGoogleMapsMarkerClusterer(onLoadCallback?: () => void) {
        this.loadScriptFromLink(
            'https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js',
            ScriptLoadStrategy.ASYNC,
            onLoadCallback
        );
    }

    loadhotjar() {
        this.loadScriptFromCode(`  (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:1677686,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`);
    }

    loadZohoChat() {
        this.chatService.init();
        // Chat With Agent Service Call
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.loadScriptFromCode(
                `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq ||
                {widgetcode:"b38f3964b9b18bf733243abb9fe45c04fbe761ee4781012cd9f1285d3057496a", values:{},ready:function(){}};
                var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.async=true;
                s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
                $zoho.salesiq.ready=function(){
                    window.chatService.zone.run(() => { window.chatService.onScriptLoaded(); });
                }`
            );
        }
    }

    loadGTM() {
        this.googleTagAnalyticsService.init();
        // WARNING!!! Any Changes here should be made in index html for noscript
        this.loadScriptFromCode(
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${environment.gtmId}');`
        );
    }

    loadFacebookPixel() {
        this.facebookAnalyticsService.init();
        this.loadScriptFromCode(
            `! function(f, b, e, v, n, t, s) {
            if (f.fbq) return;n = f.fbq = function() {n.callMethod ?n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
            if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = '2.0';n.queue = [];t = b.createElement(e);
            t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s)}(window,
            document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');fbq('init', '1665749597021100');
            (function wait() {
                if (fbq && fbq.getState && fbq.getState().pixels[0]) {
                window.facebookAnalyticsService.zone.run(() => { window.facebookAnalyticsService.onScriptLoaded(); });
                } else {
                setTimeout(wait, 500);
                }
            })();`
        );
    }

    loadRazorpay() {
        this.loadScriptFromLink('https://checkout.razorpay.com/v1/checkout.js');
    }

    loadGoogleIdentityServices() {
        return this.loadScriptFromLink('https://accounts.google.com/gsi/client');
    }

    loadSegmentAnalytics() {
        this.segmentAnalyticsService.init();
        this.loadScriptFromCode(
            `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";
                analytics.load("iaX1uwb53FXnJUReV8er28kF01xfCD7t");
                analytics.ready(function() {
                window.segmentAnalyticsService.zone.run(() => { window.segmentAnalyticsService.onScriptLoaded(); });
            });
            }}();`
        );
    }

    loadMixpanelAnalytics() {
        // TODO Update Token For Prod. Take From ENV Variables All Tokens?
        this.mixpanelService.init();
        this.loadScriptFromCode(
            `(function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
            0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
            MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);
            mixpanel.init('${environment.mixpanelToken}', {
                loaded: function(mixpanel) {
                    window.mixpanelService.zone.run(() => { window.mixpanelService.onScriptLoaded(); });
                }
            });`
        );
    }

    loadWebengage() {
        this.loadScriptFromCode(
            `var webengage;!function(w,e,b,n,g){function o(e,t){e[t[t.length-1]]=function(){r.__queue.push([t.join("."),
            arguments])}}var i,s,r=w[b],z=" ",l="init options track screen onReady".split(z),a="webPersonalization feedback survey notification notificationInbox".split(z),c="options render clear abort".split(z),p="Prepare Render Open Close Submit Complete View Click".split(z),u="identify login logout setAttribute".split(z);if(!r||!r.__v){for(w[b]=r={__queue:[],__v:"6.0",user:{}},i=0;i < l.length;i++)o(r,[l[i]]);
            for(i=0;i < a.length;i++){for(r[a[i]]={},s=0;s < c.length;s++)o(r[a[i]],[a[i],c[s]]);for(s=0;s < p.length;s++)o(r[a[i]],[a[i],"on"+p[s]])}for(i=0;i < u.length;i++)o(r.user,["user",u[i]]);setTimeout(function(){var f=e.createElement("script"),d=e.getElementById("_webengage_script_tag");f.type="text/javascript",f.async=!0,f.src=("https:"==e.location.protocol?"https://widgets.in.webengage.com":"http://widgets.in.webengage.com")+"/js/webengage-min-v-6.0.js",d.parentNode.insertBefore(f,d)})}}
            (window,document,"webengage");webengage.init("${environment.webengageId}");`,
            '_webengage_script_tag'
        );
    }

    constructor(
        private chatService: ChatService,
        private utilsService: UtilsService,
        private facebookAnalyticsService: FacebookAnalyticsService,
        private googleTagAnalyticsService: GoogleTagAnalyticsService,
        private segmentAnalyticsService: SegmentAnalyticsService,
        private mixpanelService: MixPanelService,
        private webengageService: WebengageService
    ) {}
}
