@if (isLoggedIn) {
    <div
        class="d-flx a-items-c logout-btn-container padding-horizontal-16 padding-vertical-12"
        (click)="handleLogout()"
    >
        <div class="d-flx a-items-c j-cont-cen logout-icon-container">
            <img
                class="height-100 width-100 object-fit-contain"
                appImageShimmerLazyLoad
                [imageSrc]="CommonIconsUrl.LOGOUT"
                alt="logout"
            />
        </div>
        <p class="font-12 text-color-danger logout-label">Log out</p>
    </div>
}
