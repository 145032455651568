<!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
<div class="city c-pointer" (click)="toggleSelectCityModal(true)">
    <div class="your-city show-tab-desk">Your city</div>
    <div class="name d-flx a-items-c">
        <span>{{ selectedCity?.name }}</span>
        <span class="caret-down"></span>
    </div>
</div>

@defer {
    <app-select-city-modal [config]="selectCityModalConfig"></app-select-city-modal>
}
