import { NgModule } from '@angular/core';
import { FormsComponent } from './forms.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TextareaModule } from '../textarea/textarea.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { CommonModule } from '@angular/common';
import { MultiDropdownComponentModule } from '../multi-select-dropdown/multi-select-dropdown.module';
import { SingleSelectDropdownModule } from '../single-select-dropdown/single-select-dropdown.module';
import { FormFieldDirective } from '../../directives/form-field/form-field.directive';
import { FormButtonDirective } from '../../directives/form-button/form-button.directive';

@NgModule({
    declarations: [FormsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TextareaModule,
        DropdownModule,
        MultiDropdownComponentModule,
        SingleSelectDropdownModule,
        FormFieldDirective,
        FormButtonDirective,
    ],
    exports: [FormsComponent],
})
export class FormsModule {}
