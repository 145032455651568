export enum AnalyticsAPIs {
    GOOGLE = 'google',
    MIXPANEL = 'mixpanel',
    SEGMENT = 'segment',
    FACEBOOK = 'facebook',
    WEBENGAGE = 'webengage',
}

export enum AutosuggestEvent {
    CLICKED = 'AUTOSUGGEST_CLICKED',
    SEARCH = 'AUTOSUGGEST_SEARCH',
    RESULT_SELECTED = 'AUTOSUGGEST_RESULT_SELECTED',
    PRESUGGEST_SELECTED = 'AUTOSUGGEST_PRESUGGESTED_SELECTED',
}

export enum SpecificPageViewedEvents {
    WORKSPACE_VIEWED = 'WORKSPACE_VIEWED',
    LISTING_PAGE_VIEWED = 'LISTING_PAGE_VIEWED',
    FLEXI_PRODUCT_VIEWED = 'FLEXI_PRODUCT_VIEWED',
    DEDICATED_PRODUCT_VIEWED = 'DEDICATED_PRODUCT_VIEWED',
    TEAMFLEXI_PRODUCT_VIEWED = 'TEAMFLEXI_PRODUCT_VIEWED',
    VO_PRODUCT_VIEWED = 'VO_PRODUCT_VIEWED',
    DEDICATED_LANDING_VIEWED = 'DEDICATED_LANDING_VIEWED',
    VO_LANDING_VIEWED = 'VO_LANDING_VIEWED',
    MO_LANDING_VIEWED = 'MO_LANDING_VIEWED',
    ENTERPRISES_LANDING_VIEWED = 'ENTERPRISES_LANDING_VIEWED',
    MR_LANDING_VIEWED = 'MR_LANDING_VIEWED',
    TEAMFLEXI_LANDING_VIEWED = 'TEAMFLEXI_LANDING_VIEWED',
    TESTIMONIALS_PAGE_VIEWED = 'TESTIMONIALS_PAGE_VIEWED',
    CASE_STUDIES_PAGE_VIEWED = 'CASE_STUDIES_PAGE_VIEWED',
    REFER_AND_EARN_PAGE_VIEWED = 'REFER_AND_EARN_PAGE_VIEWED',
}

export enum DLFormFunnelStages {
    FORM_OPEN = 'DLFORM_OPEN',
    FORM_CLOSE = 'DLFORM_CLOSE',
    FORM_STEP1_SUBMIT = 'DLFORM_STEP1_SUBMIT',
    FORM_STEP2_SUBMIT = 'DLFORM_STEP2_SUBMIT',
    FORM_STEP2_SKIP = 'DLFORM_STEP2_SKIP',
    FORM_SUCCESS_ACTION = 'DLFORM_SUCCESS_ACTION',
}

export enum MOFormFunnelStages {
    FORM_OPEN = 'MOFORM_OPEN',
    FORM_CLOSE = 'MOFORM_CLOSE',
    FORM_STEP1_SUBMIT = 'MOFORM_STEP1_SUBMIT',
    FORM_STEP2_SUBMIT = 'MOFORM_STEP2_SUBMIT',
    FORM_STEP2_SKIP = 'MOFORM_STEP2_SKIP',
    FORM_SUCCESS_ACTION = 'MOFORM_SUCCESS_ACTION',
    FORM_REDIRECT = 'MOFORM_REDIRECT',
}

export enum HeaderFunnelStages {
    FORM_CALL = 'FORM_CALL',
}

export enum TeamFlexiLFormFunnelStages {
    FORM_OPEN = 'TEAMFLEXI_LFORM_OPEN',
    FORM_CLOSE = 'TEAMFLEXI_LFORM_CLOSE',
    FORM_STEP1_SUBMIT = 'TEAMFLEXI_LFORM_STEP1_SUBMIT',
    FORM_STEP2_SUBMIT = 'TEAMFLEXI_LFORM_STEP2_SUBMIT',
    FORM_STEP2_SKIP = 'TEAMFLEXI_LFORM_STEP2_SKIP',
}

export enum MeetingRoomLFormFunnelStages {
    MR_LFORM_OPEN = 'MR_LFORM_OPEN',
    MR_LFORM_CLOSE = 'MR_LFORM_CLOSE',
    MR_LFORM_SUBMIT = 'MR_LFORM_SUBMIT',
}

export enum HomeFormFunnelStages {
    FORM_OPEN = 'HOMEPAGEFORM_OPEN',
    FORM_STEP1_SUBMIT = 'HOMEPAGEFORM_STEP1_SUBMIT',
    FORM_STEP2_SUBMIT = 'HOMEPAGEFORM_STEP2_SUBMIT',
    FORM_STEP2_SKIP = 'HOMEPAGEFORM_STEP2_SKIP',
}

export enum FlexiTrialFunnelStages {
    FTRIAL_PAGE_VIEWED = 'FREETRIAL_PAGE_VIEWED',
    WORKSPACE_VIEWED = 'FREETRIAL_WORKSPACE_VIEWED',
    FORM_OPEN = 'FTFORM_OPEN',
    FORM_CLOSE = 'FTFORM_CLOSE',
    TRIAL_DETAIL_FORM_SUBMIT = 'FTFORM_STEP1_SUBMIT',
    TRIAL_OTP_RESEND = 'FTFORM_OTP_RESEND',
    MODIFY_PHONE = 'FTFORM_MODIFY_PHONE',
    TRIAL_VERIFY_FORM_SUBMIT = 'FTFORM_STEP2_SUBMIT',
    SUCCESS = 'FTRIAL_SUCCESS_PAGE',
    FAIL = 'FTRIAL_FAILURE_PAGE',
    FAILURE_ACTION = 'FTRIAL_FAILURE_ACTION',
}

export enum FlexiSubFunnelStages {
    FLEXI_SUB_SELECTED = 'FLEXI_SUB_SELECTED',
    FLEXI_SUB_DETAILS = 'FLEXI_SUB_DETAILS',
    FLEXI_SUB_CHECKOUT = 'FLEXI_SUB_CHECKOUT',
    FLEXI_SUB_PAY_SUCCESS = 'FLEXI_SUB_PAY_SUCCESS',
    FLEXI_SUB_PAY_FAILURE = 'FLEXI_SUB_PAY_FAILURE',
}

export enum FlexiAppDownloadEvents {
    APP_DOWNLOAD_BANNER_CLICKED = 'APP_DOWNLOAD_BANNER_CLICKED',
}

export enum DVisitFormFunnelStages {
    FORM_OPEN = 'DVISIT_FORM_OPEN',
    FORM_CLOSE = 'DVISIT_FORM_CLOSE',
    ABOUT_OPEN = 'DVISIT_FORM_ABOUT_OPEN',
    ABOUT_CLOSE = 'DVISIT_FORM_ABOUT_CLOSE',
    STEP1_SUBMIT = 'DVISIT_FORM_STEP1_SUBMIT',
    STEP2_SUBMIT = 'DVISIT_FORM_STEP2_SUBMIT',
    STEP2_SKIP = 'DVISIT_FORM_STEP2_SKIP',
    SPACES_ADDED = 'DVISIT_SPACES_ADDED',
    SUCCESS = 'DVISIT_SUCCESS_PAGE',
}

export enum ExitIntentFormEvents {
    LFORM_SHOW = 'EXITINTENT_LFORM_SHOW',
    LFORM_SUBMIT = 'EXITINTENT_LFORM_STEP1_SUBMIT',
    LFORM_CLOSE = 'EXITINTENT_LFORM_CLOSE',
}

export enum VirtualOfficeLFormFunnelStages {
    FORM_OPEN = 'VO_LFORM_OPEN',
    FORM_CLOSE = 'VO_LFORM_CLOSE',
    FORM_STEP1_SUBMIT = 'VO_LFORM_STEP1_SUBMIT',
    FORM_REDIRECT = 'VO_LFORM_REDIRECT',
}

export enum MeetingRoomFormFunnelStages {
    FORM_OPEN = 'MR_LFORM_OPEN',
    FORM_CLOSE = 'MR_LFORM_CLOSE',
    FORM_STEP1_SUBMIT = 'MR_LFORM_STEP1_SUBMIT',
    FORM_SUBMIT = 'MR_LFORM_SUBMIT',
    FORM_STEP2_SKIP = 'MR_LFORM_STEP2_SKIP',
}

export enum VirtualOfficeEvents {
    VO_PD_SEE_ALL_LOCATIONS_REDIRECT = 'VO_PD_SEE_ALL_LOCATIONS_REDIRECT',
    VO_PD_EXPLORE_LOCATIONS_CTA_REDIRECT = 'VO_PD_EXPLORE_LOCATIONS_CTA_REDIRECT',
    VO_PD_MONEY_BACK_TandC = 'VO_PD_MONEY_BACK_TandC',
    VO_PD_EXPLORE_NOW_CTA_REDIRECT = 'VO_PD_EXPLORE_NOW_CTA_REDIRECT',
    VO_PD_ALL_CITIES = 'VO_PD_ALL_CITIES',
    VO_PD_FORM = 'VO_PD_FORM',
    VO_PD_FORM_SUBMIT = 'VO_PD_FORM_SUBMIT',
    VO_PD_COMMUNITY_CTA_REDIRECT = 'VO_PD_COMMUNITY_CTA_REDIRECT',
    VO_PD_FORM_SUCCESS = 'VO_PD_FORM_SUCCESS',
    VO_PD_FORM_OPEN = 'VO_PD_FORM_OPEN',
    VO_PD_FORM_CLOSE = 'VO_PD_FORM_CLOSE',
    VO_BEGIN_KYC = 'VO_BEGIN_KYC',
}

export enum ContactUsFormFunnelStages {
    FORM_SUBMIT = 'CONTACT_US_LFORM_SUBMIT',
}

export enum AppDownloadFunnelStages {
    APP_DOWNLOAD_REDIRECT = 'APP_DOWNLOAD_REDIRECT',
}

export enum VOSpaceListingEvents {
    SL_COMPARE_PLANS = 'SL_COMPARE_PLANS',
    SL_QUOTE_FORM_OPEN = 'SL_QUOTE_FORM_OPEN',
    SL_QUOTE_FORM_SUBMIT = 'SL_QUOTE_FORM_SUBMIT',
    SL_QUOTE_FORM_CLOSE = 'SL_QUOTE_FORM_CLOSE',
    SL_QUOTE_FORM_REDIRECT = 'SL_QUOTE_FORM_REDIRECT',
}

export enum SpaceDetailPageEvents {
    WORKSPACE_3D_VIEW_OPEN = 'WORKSPACE_3D_VIEW_OPEN',
}

export enum VOSpaceDetailEvents {
    VO_SD_GET_QUOTE_FORM_OPEN = 'VO_SD_GET_QUOTE_FORM_OPEN',
    VO_SD_GET_QUOTE_FORM_SUBMIT = 'VO_SD_GET_QUOTE_FORM_SUBMIT',
    VO_SD_GET_QUOTE_FORM_CLOSE = 'VO_SD_GET_QUOTE_FORM_CLOSE',
}

export enum VOCompareWorkspaceEvents {
    VO_CW_GET_QUOTE_FORM_OPEN = 'VO_CW_GET_QUOTE_FORM_OPEN',
    VO_CW_GET_QUOTE_FORM_SUBMIT = 'VO_CW_GET_QUOTE_FORM_SUBMIT',
    VO_CW_GET_QUOTE_FORM_CLOSE = 'VO_CW_GET_QUOTE_FORM_CLOSE',
    CW_PAGE_VIEWED = 'CW_PAGE_VIEWED',
    CW_INIT = 'CW_INIT',
    ADD_TO_COMPARE = 'ADD_TO_COMPARE',
    REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE',
}

export enum DedicatedSpaceVisitEvents {
    SHORTLISTED = 'SHORTLISTED',
    MY_SHORTLISTS_VIEWED = 'MY_SHORTLISTS_VIEWED',
    SCHEDULE_VISIT_CLICKED = 'SCHEDULE_VISIT_CLICKED',
}

export enum VOPaymentEvents {
    VO_PLAN_SELECTED = 'VO_PLAN_SELECTED',
    VO_PLAN_CHECKOUT = 'VO_PLAN_CHECKOUT',
    VO_PLAN_SUCCESS = 'VO_PLAN_SUCCESS',
    VO_PLAN_FAILURE = 'VO_PLAN_FAILURE',
}

export enum UserReviewsEvents {
    BOOKING_RATING_INPUT_CLICKED = 'BOOKING_RATING_INPUT_CLICKED',
    BOOKING_REVIEW_SUBMITTED = 'BOOKING_REVIEW_SUBMITTED',
}

export enum MobileOtpFormFunnelStages {
    LOGIN_POPUP_VIEWED = 'LOGIN_POPUP_VIEWED',
    OTP_SUBMITTED = 'OTP_SUBMITTED',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
}

export enum FlexiBookingEvents {
    BOOKING_PAGE_VIEWED = 'BOOKING_PAGE_VIEWED',
    BOOKING_INITIATED = 'BOOKING_INITIATED',
    BOOKING_CONFIRM_PAGE_VIEWED = 'BOOKING_CONFIRM_PAGE_VIEWED',
    BOOKING_PROCEED_CLICKED = 'BOOKING_PROCEED_CLICKED',
    PMODE_SELECTION_PAGE_VIEWED = 'PMODE_SELECTION_PAGE_VIEWED',
    PMODE_SELECTED = 'PMODE_SELECTED',
    FLEXI_RECHARGE_PAGE_VIEWED = 'FLEXI_RECHARGE_PAGE_VIEWED',
    ORDER_SUMMARY_PAGE_VIEWED = 'ORDER_SUMMARY_PAGE_VIEWED',
    MYHQ_PAYMENT_INITIATED = 'MYHQ_PAYMENT_INITIATED',
    MYHQ_PAYMENT_SUCCESS = 'MYHQ_PAYMENT_SUCCESS',
    MYHQ_PAYMENT_FAILURE = 'MYHQ_PAYMENT_FAILURE',
    BOOKING_FAILED = 'BOOKING_FAILED',
    BOOKING_SUCCESS = 'BOOKING_SUCCESS',
    BOOKING_SUCCESS_MR = 'BOOKING_SUCCESS_MR',
    BOOKING_SUCCESS_FLEXI = 'BOOKING_SUCCESS_FLEXI',
}

export enum LandingPageType {
    PAN_INDIA = 'PAN_INDIA',
    CITY_SPECIFIC = 'CITY_SPECIFIC',
}

export enum OrderSummaryEntityType {
    INR = 'INR',
    HQ_WALLET = 'HQ_WALLET',
}

export enum OrderSummarySource {
    BOOKING_FLOW = 'BOOKING_FLOW',
    HQ_WALLET_PAGE = 'HQ_WALLET_PAGE',
}

export enum ErrorTypes {
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum LoginFailureErrorTypes {
    GENERATE_OTP_FAILURE = 'GENERATE_OTP_FAILURE',
    VERFIY_OTP_FAILURE = 'VERFIY_OTP_FAILURE',
    SIGNUP_FAILURE = 'SIGNUP_FAILURE',
}

export enum ManagedOfficeEvents {
    MO_LISTING_BANNER_OPEN = 'MO_LISTING_BANNER_OPEN',
}

export enum CompanyNameSearchEvents {
    VO_COMPANY_NAME_SEARCH_PAGE_VIEWED = 'VO_COMPANY_NAME_SEARCH_PAGE_VIEWED',
    VO_COMPANY_NAME_LEAD_FORM_OPEN = 'VO_COMPANY_NAME_LEAD_FORM_OPEN',
    VO_COMPANY_NAME_LEAD_FORM_CLOSE = 'VO_COMPANY_NAME_LEAD_FORM_CLOSE',
    VO_COMPANY_NAME_LEAD_FORM_SUBMIT = 'VO_COMPANY_NAME_LEAD_FORM_SUBMIT',
    VO_COMPANY_NAME_SEARCH = 'VO_COMPANY_NAME_SEARCH',
    VO_COMPANY_NAME_SEARCH_SUCCESS = 'VO_COMPANY_NAME_SEARCH_SUCCESS',
    VO_COMPANY_NAME_SEARCH_FAILURE = 'VO_COMPANY_NAME_SEARCH_FAILURE',
}

export enum CompanyRegistrationEvents {
    VO_COMPANY_REGISTRATION_SEARCH_PAGE_VIEWED = 'VO_COMPANY_REGISTRATION_SEARCH_PAGE_VIEWED',
    VO_COMPANY_REGISTRATION_LEAD_FORM_OPEN = 'VO_COMPANY_REGISTRATION_LEAD_FORM_OPEN',
    VO_COMPANY_REGISTRATION_LEAD_FORM_CLOSE = 'VO_COMPANY_REGISTRATION_LEAD_FORM_CLOSE',
    VO_COMPANY_REGISTRATION_LEAD_FORM_SUBMIT = 'VO_COMPANY_REGISTRATION_LEAD_FORM_SUBMIT',
}

export enum GstRegistrationEvents {
    VO_GST_REGISTRATION_SEARCH_PAGE_VIEWED = 'VO_GST_REGISTRATION_SEARCH_PAGE_VIEWED',
    VO_GST_REGISTRATION_LEAD_FORM_OPEN = 'VO_GST_REGISTRATION_LEAD_FORM_OPEN',
    VO_GST_REGISTRATION_LEAD_FORM_CLOSE = 'VO_GST_REGISTRATION_LEAD_FORM_CLOSE',
    VO_GST_REGISTRATION_LEAD_FORM_SUBMIT = 'VO_GST_REGISTRATION_LEAD_FORM_SUBMIT',
}

export enum VPOBEvents {
    VO_VPOB_PAGE_VIEWED = 'VO_VPOB_PAGE_VIEWED',
    VO_VPOB_LEAD_FORM_OPEN = 'VO_VPOB_LEAD_FORM_OPEN',
    VO_VPOB_LEAD_FORM_CLOSE = 'VO_VPOB_LEAD_FORM_CLOSE',
    VO_VPOB_LEAD_FORM_SUBMIT = 'VO_VPOB_LEAD_FORM_SUBMIT',
}
