import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonIconsUrl } from '../../../../../core/constants/common-icons-url';
import { AuthService } from '../../../../services/auth/auth.service';
import { AuthStatus } from '../../../../../modules/auth/auth.enum';
import { Subscription } from 'rxjs';
import { productKeys } from '../../../../enums/enums';
import { MobileOtpAuthService } from '../../../../services/auth/mobile-otp-auth.service';

import { DirectivesModule } from '../../../../directives/directives.module';

@Component({
    selector: 'app-logout-button',
    templateUrl: './logout-button.component.html',
    styleUrls: ['./logout-button.component.scss'],
    standalone: true,
    imports: [DirectivesModule],
})
export class LogoutButtonComponent implements OnInit, OnDestroy {
    CommonIconsUrl = CommonIconsUrl;
    isLoggedIn = false;
    authStatusSub: Subscription;
    @Input() product: productKeys;
    authService;
    constructor(
        private voAuthService: AuthService,
        private mobileAuthService: MobileOtpAuthService
    ) {}

    ngOnInit(): void {
        this.authService = this.product === productKeys.VIRTUAL_OFFICE ? this.voAuthService : this.mobileAuthService;
        this.authStatusSub = this.authService.getAuthStatusSub().subscribe(authStatus => {
            this.isLoggedIn = authStatus === AuthStatus.AUTHENTICATED;
        });
    }

    ngOnDestroy() {
        if (this.authStatusSub) {
            this.authStatusSub.unsubscribe();
        }
    }

    handleLogout() {
        this.authService.logout().subscribe(success => {
            if (success) {
                this.authService.setUserData(null);
                this.authService.setAuthStatus(AuthStatus.UNAUTHENTICATED);
            }
        });
    }
}
