@if (field) {
    <div [attr.formGroup]="form" [className]="additionalClass ? additionalClass + ' line-height-18' : 'line-height-18'">
        <button
            [type]="field.htmlType || buttonHTMLType.submit"
            [class]="config.cssClass"
            (click)="onClickEmitEvent($event)"
            [disabled]="form?.invalid || field.showLoader || field.disabled"
            [ngClass]="{
                'gap-4': field?.type == buttonType.LINK,
                'gap-8': field?.type != buttonType.LINK,
                'disable-btn': form?.invalid || field.showLoader || field.disabled,
            }"
        >
            @if (!field.showLoader) {
                @if (field.subLabel) {
                    <div class="sub-label">
                        {{ field.subLabel }}
                    </div>
                }
                <div
                    class="d-flx a-items-c"
                    [ngClass]="{
                        'gap-4': field?.type == buttonType.LINK,
                        'gap-8 j-cont-cen': field?.type != buttonType.LINK,
                    }"
                >
                    @if (iconPosition === 'left') {
                        <ng-container [ngTemplateOutlet]="buttonIcon"></ng-container>
                    }
                    @if (field.label) {
                        <div
                            [ngClass]="{
                                'text-ellipsis': showEllipsis,
                                'next-line-wrap': !showEllipsis,
                            }"
                            #label
                        >
                            {{ field.label }}
                        </div>
                    }
                    @if (iconPosition === 'right') {
                        <ng-container [ngTemplateOutlet]="buttonIcon"></ng-container>
                    }
                </div>
            }
            @if (field.showLoader) {
                <app-loaders class="d-flx" [config]="field.loaderConfig"></app-loaders>
            }
        </button>
    </div>
}

<ng-template #buttonIcon>
    @if (field?.icon || field?.iconUrl) {
        <div class="d-flx j-cont-cen a-items-c icon-container">
            <img
                class="height-100 width-100"
                [src]="
                    field?.iconUrl
                        ? field?.iconUrl
                        : ImageBaseUrl.IMAGE_URL_BASE + '/web/ssr/common/assets/' + field.icon
                "
                alt="i"
                loading="lazy"
            />
        </div>
    }
</ng-template>
