import { UtilsService } from '../../utils/utils.service';
import { map } from 'rxjs/operators';
import { ObserverQueue } from '../../../classes/observerQueue.class';
import { Injectable, NgZone } from '@angular/core';
import { interval, zip } from 'rxjs';
import { toPromiseAndEnqueueTask } from '../../../decorators/toPromiseAndEnqueueTask.decorator';

const queue = new ObserverQueue();
@Injectable({
    providedIn: 'root',
})
export class SegmentAnalyticsService {
    constructor(
        private ngZone: NgZone,
        private utilsService: UtilsService
    ) {}

    init() {
        window['segmentAnalyticsService'] = {
            component: this,
            zone: this.ngZone,
            onScriptLoaded: () => this.onScriptLoaded(),
        };
    }

    onScriptLoaded() {
        zip(queue, interval(10))
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .pipe(map(([queueItem, d]) => queueItem))
            .subscribe(({ event, resolve }) => {
                resolve(event());
            });
    }

    @toPromiseAndEnqueueTask(queue)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackEvent(event: string, properties: object): any {
        if (window.analytics) {
            window.analytics.track(event, properties);
        } else {
            console.warn('Segment Not Loaded');
        }
    }

    @toPromiseAndEnqueueTask(queue)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    trackPageNavigation(properties): any {
        // TODO: Fix Segment Analytics - Tracks Incorrect Page Views
        if (window.analytics) {
            // window.analytics.page();
        } else {
            console.warn('Segment Not Loaded');
        }
    }

    @toPromiseAndEnqueueTask(queue)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    identifyUser(uniqId, user): any {
        // here uniqueId is phone number
        if (window.analytics) {
            if (uniqId) {
                window.analytics.identify(uniqId, user);
            } else {
                window.analytics.identify(user);
            }
        } else {
            console.warn('Segment Not Loaded');
        }
    }
}
