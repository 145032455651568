import { Router, ActivatedRoute } from '@angular/router';
import { ErrorService } from './../error/error.service';
import { environment } from './../../../../environments/environment';
import { IHttpResponse } from './../../../shared/interfaces/HTTPResponse.interface';
import { HttpClient } from '@angular/common/http';
import { map, catchError, switchMap, filter } from 'rxjs/operators';
import { UtilsService } from './../utils/utils.service';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FaqService } from '../../../shared/services/faq.service';
import { IFAQContent } from '../../../shared/interfaces/faq.interface';

interface ISeoData {
    title: string;
    description: string;
    image?: { url: string; alt: string };
    canonicalUrl?: string;
    metaRobots?: string;
    keywords: string[];
}

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    private serviceUrl = `${environment.baseUrl}/seo`;

    constructor(
        private title: Title,
        private meta: Meta,
        @Inject(DOCUMENT) private doc,
        private utilsService: UtilsService,
        private http: HttpClient,
        private errorService: ErrorService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private faqService: FaqService
    ) {}

    updateTitle(title: string) {
        this.title.setTitle(title);
        this.meta.updateTag({ property: 'og:title', content: title });
        this.meta.updateTag({ name: 'twitter:title', content: title });
    }

    updateDescription(description: string) {
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ property: 'og:description', content: description });
        this.meta.updateTag({ name: 'twitter:description', content: description });
    }

    updateOgTagUrl(url?: string) {
        if (!url) {
            url = `https://myhq.in${this.router.url.split('?')[0]}`;
        }
        this.meta.updateTag({ property: 'og:url', content: url });
        this.meta.updateTag({ name: 'twitter:url', content: url });
    }

    updateCanonicalUrl(url?: string) {
        if (!url) {
            this.removeCanonicalTag();
        } else {
            this.updateCanonicalTagUrl(url);
        }
    }

    updateImage(image: string, alt: string) {
        this.meta.updateTag({ property: 'og:image', content: image });
        this.meta.updateTag({ name: 'twitter:image:src', content: image });
        this.meta.updateTag({ property: 'og:image:alt', content: alt });
        this.meta.updateTag({ name: 'twitter:image:alt', content: alt });
    }

    updateRobots(robots: string) {
        if (!environment.production) {
            this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
            return;
        }
        if (robots) {
            this.meta.updateTag({ name: 'robots', content: robots });
        }
    }

    updateKeywords(keywords: string) {
        this.meta.updateTag({ name: 'keywords', content: keywords });
    }

    updateCanonicalTagUrl(url: string) {
        // Find the existing canonical link tag, if it exists
        let link = this.doc.querySelector('link[rel="canonical"]') as HTMLLinkElement;

        // If the canonical link tag doesn't exist, create a new one
        if (!link) {
            link = this.doc.createElement('link');
            link.setAttribute('rel', 'canonical');
            this.doc.head.appendChild(link);
        }

        // Set the href attribute to the provided URL
        link.setAttribute('href', url);
    }

    removeCanonicalTag() {
        const canonicalElement = this.doc.querySelector('link[rel="canonical"]');
        if (canonicalElement) {
            this.doc.removeChild(canonicalElement);
        }
    }

    getSeoDataByUrl(url: string) {
        const apiUrl = `${this.serviceUrl}?url=${encodeURIComponent(url)}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.get<IHttpResponse<any>>(apiUrl).pipe(catchError(this.errorService.handleError));
    }

    updateSeoTags(data: ISeoData) {
        const sanitizedTitle = this.sanitizeExtraSpacesInString(data.title);
        if (sanitizedTitle) {
            this.updateTitle(sanitizedTitle);
        }

        const sanitizedDescription = this.sanitizeExtraSpacesInString(data.description);
        if (sanitizedDescription) {
            this.updateDescription(sanitizedDescription);
        }

        if (data.image) {
            this.updateImage(data.image.url, data.image.alt);
        }
        if (data.keywords && data.keywords.length > 0) {
            this.updateKeywords(data.keywords.join(', '));
        }
        this.updateOgTagUrl(data.canonicalUrl);
        this.updateCanonicalUrl(data.canonicalUrl);
        this.updateRobots(data.metaRobots);
    }

    updateFaqsData(faqs: IFAQContent[]) {
        this.faqService.setFaqData(faqs);
    }

    sanitizeExtraSpacesInString(str: string) {
        return str?.replace(/\s\s+/g, ' ');
    }

    subscribeToRouterEvents() {
        this.utilsService
            .getRouterEvents$()
            .pipe(
                map(() => this.activatedRoute),
                filter(route => route.outlet === 'primary'),
                map(() => this.router.url),
                switchMap(url => this.getSeoDataByUrl(url))
            )
            .subscribe(res => {
                if (res && res.success) {
                    try {
                        let faqs = res.data.faqs || [];
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        faqs = faqs?.map((faq: any) => ({
                            question: faq.q,
                            answer: faq.a,
                        }));
                        this.updateSeoTags(res.data.meta || {});
                        this.updateFaqsData(faqs);
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    } catch (e) {
                        console.warn(
                            `SEO update call for "${this.router.url}" failed with data ${JSON.stringify(res)}`
                        );
                    }
                }
            });
    }
}
