@if (deviceInfo.isMobile) {
    <div (clickOutside)="toggleDropdown(false)" appClickOutside>
        @for (item of config | filterBy: 'show'; track itemById($index, item)) {
            <ng-container
                [ngTemplateOutlet]="dropdownItem"
                [ngTemplateOutletContext]="{ item, level: 1 }"
            ></ng-container>
        }
    </div>
}

<ng-template #dropdownItem let-item="item" let-level="level">
    <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
    <div
        class="d-flx a-items-c j-cont-space-bet dropdown-item padding-vertical-12 c-pointer"
        (click)="handleDropdownToggle(item?.id)"
        [style]="calcStyles(level)"
        [ngClass]="item?.type === menuType?.ACCORDION ? 'padding-horizontal-24' : 'padding-horizontal-16'"
    >
        <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
        <p
            class="dropdown-label text-color-dark font-bold font-12"
            [ngClass]="{ 'selected-link-container': isSelected(item?.link) }"
            (click)="handleNavigation($event, item?.link)"
        >
            {{ item.label }}
        </p>

        @if (item.type === menuType.ACCORDION) {
            <div
                class="arrow arrow-down margin-right-8"
                [ngClass]="{ 'arrow-down': !item.isOpen, 'arrow-up': item.isOpen }"
            ></div>
        }
    </div>

    <div [@dropdownAnimation]="item.isOpen ? 'open' : 'closed'">
        @for (item of item.items | filterBy: 'show'; track itemById($index, item)) {
            <ng-container
                [ngTemplateOutlet]="dropdownItem"
                [ngTemplateOutletContext]="{ item, level: level + 1 }"
            ></ng-container>
        }
    </div>
</ng-template>
