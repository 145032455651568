// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'https://api.web.gohq.in',
    env: 'qa',
    mixpanelToken: 'f553211b947056846a2a983067f5f41c',
    SERVER_URL: 'https://gohq.in',
    teamDashboardUrl: 'https://team.gohq.in',
    loginDashboardUrl: '/my-account',
    razorpayKey: 'rzp_test_KLEFNcmkL5S0n2',
    GOOGLE_OAUTH_CLIENT_ID: '998436178493-263bi91v42tci841qi8v1blrm3n6s2ia.apps.googleusercontent.com',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCEZvr9VRGUZku3WBSWLiGNBWoQU8vww1Y',
    gtmId: 'GTM-PJ975WL2',
    webengageId: 'in~11b5642d6',
    domain: 'gohq.in',
    sentryDSN: 'https://1f90dde3cf974f2093dad3e213de268f@o441376.ingest.sentry.io/5412907',
    performanceTracking: false, // can't enable in qa as prod check in performance tracking service
    newRelicConfig: {
        accountID: '',
        trustKey: '',
        agentID: '',
        licenseKey: '',
        applicationID: '',
        beacon: '',
        errorBeacon: '',
        sa: 1,
    },
    cloudName: 'myhq',
    disableCloudinary: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
