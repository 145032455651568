import { Injectable } from '@angular/core';
import {
    ICheckExistingUserPayload,
    ICheckExistingUserResponse,
    IForgetPasswordPayload,
    ILoginResponse,
    ILoginSignupPayload,
    ISignupResponse,
    IUpdateUserPayload,
    IUser,
    IUserInfoResponse,
} from '../../../modules/auth/auth.interface';
import { IHttpResponse } from '../../interfaces/HTTPResponse.interface';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '../../../core/services/error/error.service';
import { AuthStatus, LoginFormStages } from '../../../modules/auth/auth.enum';
import { CustomHttpParams } from '../../../core/interceptors/custom-params.interceptor';
import { InterceptorMetadataKeys } from '../../enums/enums';

type LoginFormData = {
    stage: LoginFormStages;
    emailStageData?: { email: string };
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private loginModalStage = new BehaviorSubject<LoginFormData>({ stage: LoginFormStages.ENTER_EMAIL });
    private loginModalViewState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private authStatus: BehaviorSubject<AuthStatus> = new BehaviorSubject<AuthStatus>(null);
    private userData$: BehaviorSubject<IUserInfoResponse> = new BehaviorSubject<IUserInfoResponse>(null);
    private userData: IUserInfoResponse;

    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    verify = () => {
        this.getUser().subscribe({
            next: userInfo => {
                if (userInfo) {
                    this.setUserData(userInfo);
                    this.setAuthStatus(AuthStatus.AUTHENTICATED);
                    if (!userInfo.user?.name) {
                        this.toggleLoginModal(true, LoginFormStages.CREATE_PROFILE);
                    }
                }
            },
        });
    };

    isExistingUser = (payload: ICheckExistingUserPayload): Observable<ICheckExistingUserResponse> => {
        const url = `${environment.baseUrl}/auth/web/validateuserexistence`;
        return this.http.post<IHttpResponse<ICheckExistingUserResponse>>(url, payload).pipe(
            map(res => {
                return res.data;
            }),
            catchError(this.handleAuthError)
        );
    };

    signup = (payload: ILoginSignupPayload): Observable<ISignupResponse> => {
        const url = `${environment.baseUrl}/auth/web/signup`;
        return this.http.post<IHttpResponse<ISignupResponse>>(url, payload).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                }
            }),
            catchError(this.errorService.handleError)
        );
    };

    login = (payload: ILoginSignupPayload): Observable<IHttpResponse<ILoginResponse>> => {
        const url = `${environment.baseUrl}/auth/web/login`;
        return this.http.post<IHttpResponse<ILoginResponse>>(url, payload).pipe(
            map(res => {
                return res;
            }),
            catchError(this.errorService.handleError)
        );
    };

    logout = () => {
        const url = `${environment.baseUrl}/auth/web/logout`;
        const customHttpParams = new CustomHttpParams({
            interceptorMetadata: {
                [InterceptorMetadataKeys.HQ_DISABLE_CACHE]: true,
            },
        });
        const httpOptions = {
            params: customHttpParams,
        };
        return this.http.post<IHttpResponse<void>>(url, {}, httpOptions).pipe(
            map(res => {
                return res?.success;
            })
        );
    };

    forgetPassword = (payload: IForgetPasswordPayload): Observable<boolean> => {
        const url = `${environment.baseUrl}/auth/web/forgot-password`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.post<IHttpResponse<any>>(url, payload).pipe(
            map(res => {
                return res.success;
            }),
            catchError(this.handleAuthError)
        );
    };

    getUser = (): Observable<IUserInfoResponse> => {
        if (this.userData) {
            return of(this.userData);
        } else {
            const url = `${environment.baseUrl}/authUsers/web/profile`;
            const customHttpParams = new CustomHttpParams({
                interceptorMetadata: {
                    [InterceptorMetadataKeys.HQ_DISABLE_CACHE]: true,
                },
            });
            const httpOptions = {
                params: customHttpParams,
            };
            return this.http.get<IHttpResponse<IUserInfoResponse>>(url, httpOptions).pipe(
                map(res => {
                    if (res?.success) {
                        return res.data;
                    }
                }),
                catchError(this.handleAuthError)
            );
        }
    };

    updateUserDetails = (payload: IUpdateUserPayload): Observable<IUser> => {
        const url = `${environment.baseUrl}/authUsers/web/profile/details`;
        return this.http.put<IHttpResponse<IUser>>(url, payload).pipe(
            map(res => {
                if (res?.success) {
                    return res.data;
                }
            }),
            catchError(this.handleAuthError)
        );
    };

    updateLoginFormStage(stage: LoginFormStages): void;
    updateLoginFormStage(stage: LoginFormStages.ENTER_EMAIL, email?: string): void;
    updateLoginFormStage(stage: LoginFormStages, email?: string): void {
        if (email) {
            this.loginModalStage.next({ stage, emailStageData: { email } });
        } else {
            this.loginModalStage.next({ stage });
        }
    }

    getLoginModalStageSub = (): Observable<LoginFormData> => {
        return this.loginModalStage;
    };

    toggleLoginModal = (show: boolean, formStage?: LoginFormStages) => {
        this.loginModalViewState.next(show);
        if (formStage) {
            this.updateLoginFormStage(formStage);
        } else {
            // If not form stage is passed, then open enter email stage by default
            this.updateLoginFormStage(LoginFormStages.ENTER_EMAIL);
        }
    };

    openEmailStageWithPreFilledEmail = (email: string) => {
        this.loginModalViewState.next(true);
        this.updateLoginFormStage(LoginFormStages.ENTER_EMAIL, email);
    };

    getLoginViewStateSub = (): Observable<boolean> => {
        return this.loginModalViewState;
    };

    setAuthStatus = (status: AuthStatus) => {
        this.authStatus.next(status);
    };

    getAuthStatusSub = (): Observable<AuthStatus> => {
        return this.authStatus;
    };

    setUserData = (data: IUserInfoResponse) => {
        this.userData = data;
        this.userData$.next(data);
    };

    getUserDataSub = (): Observable<IUserInfoResponse> => {
        return this.userData$;
    };

    handleAuthError = (err: HttpErrorResponse) => {
        if (err.status === 401 && this.authStatus.value === AuthStatus.AUTHENTICATED) {
            this.logout().subscribe(success => {
                if (success) {
                    this.setUserData(null);
                    this.setAuthStatus(AuthStatus.UNAUTHENTICATED);
                }
            });
            return this.errorService.handleError(err);
        } else {
            // commenting this bcz its console log 401 error
            return this.errorService.handleError(err);
        }
    };
}
