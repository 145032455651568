import { NgModule } from '@angular/core';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { ScrollFadeDirective } from './scroll-fade/scroll-fade.directive';
import { ImageProgressiveLazyLoadDirective } from './lazy-load/image-progressive-lazy-load.directive';
import { ImageShimmerLazyLoadDirective } from './lazy-load/image-shimmer-lazy-load.directive';
import { FadeOutDirective } from './fade-out/fade-out.directive';
import { FormExternalSubmitBtnDirective } from './form-external-submit/form-external-submit-btn.directive';
import { InfiniteScrollDirective } from './infinite-scroll/infinite-scroll.directive';
import { StructuredContentDirective } from './structured-content/structured-content.directive';
import { DropdownDirective } from './dropdown/dropdown.directive';

@NgModule({
    declarations: [
        ScrollFadeDirective,
        FadeOutDirective,
        ImageProgressiveLazyLoadDirective,
        ImageShimmerLazyLoadDirective,
        InfiniteScrollDirective,
        AutofocusDirective,
        ClickOutsideDirective,
        FormExternalSubmitBtnDirective,
        StructuredContentDirective,
        DropdownDirective,
    ],
    imports: [],
    exports: [
        ScrollFadeDirective,
        FadeOutDirective,
        ImageProgressiveLazyLoadDirective,
        ImageShimmerLazyLoadDirective,
        InfiniteScrollDirective,
        ClickOutsideDirective,
        FormExternalSubmitBtnDirective,
        StructuredContentDirective,
        DropdownDirective,
    ],
})
export class DirectivesModule {}
