import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(items: any[], property: string, conditionToMatch: any = true): any[] {
        if (!items) {
            return [];
        }
        if (!property) {
            return items;
        }
        // If needed, update this to adapt for better filtering
        return items.filter(item => item[property] === conditionToMatch);
    }
}
