@if (areaMetaDataList?.length > 0) {
    <div class="area-metadata-container">
        <div class="area-blocks-container">
            @for (areaMetadata of areaMetaDataList; track areaMetadata; let i = $index) {
                <div class="area-block flx-1" #contentContainer>
                    @if (areaMetadata.title) {
                        <div class="area-heading">
                            <h1>{{ areaMetadata.title }}</h1>
                        </div>
                    }
                    <div class="area-desc" [ngClass]="{ expanded: areaMetadata.isExpanded }">
                        <div class="content-container injected-content" [innerHTML]="areaMetadata?.about"></div>
                    </div>
                    @if (areaMetadata.ctaConfig.show) {
                        <div class="d-flx flx-d-row a-items-c view-content-cta c-pointer" (click)="toggleViewMore(i)">
                            <p class="view-content-label">{{ areaMetadata.ctaConfig.label }}</p>
                            <div class="d-flx flx-d-row a-items-c j-cont-cen view-content-cta-icon-container">
                                <img
                                    class="view-icon"
                                    appImageShimmerLazyLoad
                                    [imageSrc]="areaMetadata.ctaConfig.icon"
                                    alt="icon"
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
}
