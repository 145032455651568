import { Component, OnInit, Input } from '@angular/core';

export interface ILoaderConfig {
    color?: string;
    size?: ELoaderSize;
}

export enum ELoaderSize {
    SMALL = 'SMALL',
}

@Component({
    selector: 'app-loaders',
    templateUrl: './loaders.component.html',
    styleUrls: ['./loaders.component.scss'],
})
export class LoadersComponent implements OnInit {
    config: ILoaderConfig;
    cssClasses = [];

    @Input('config') set setConfig(value: ILoaderConfig) {
        this.config = value;
        this.updateClasses();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    updateClasses() {
        switch (this.config?.size) {
            case ELoaderSize.SMALL: {
                this.cssClasses.push('small');
                break;
            }
        }
    }
}
