import { ElementRef, TemplateRef } from '@angular/core';
import { LocationWorkspaceSearchSuggestionType } from '../../../../shared/enums/enums';
import { ICity } from '../../../../shared/interfaces/location.interface';
import { IProductMap } from '../../../../shared/interfaces/product-interface';
import { ITypeaheadConfig } from '../../../../shared/interfaces/typeahead.interface';

export interface LocationWorkspaceSearchConfig {
    typeaheadConfig?: ITypeaheadConfig;
    product: IProductMap;
    showSearchModal: boolean;
    searchSuggestionType: LocationWorkspaceSearchSuggestionType;
    type?: LocationWorkspaceType;
    typeaheadInitValue?: string;
    setFocusOnInit?: boolean;
    showStaticResultByDefault?: boolean;
    typeaheadContainerClass?: string;
    customDefaultSearchResultsTemplate?: TemplateRef<ElementRef>;
    selectedCity?: ICity;
}

export enum LocationWorkspaceType {
    DEFAULT_SEARCH = 'DEFAULT_SEARCH',
    COMMON_HEADER_SEARCH = 'COMMON_HEADER_SEARCH',
    LOCALITY_FILTER_SEARCH = 'LOCALITY_FILTER_SEARCH',
}
