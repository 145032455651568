import { ObserverQueue } from './../../../classes/observerQueue.class';
import { Injectable } from '@angular/core';
import { UtilsService } from '../../utils/utils.service';
import { AnalyticsBaseInterface } from './analytics-base.interface';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const queue = new ObserverQueue();

@Injectable({
    providedIn: 'root',
})
export class MockAnalyticsBaseService implements AnalyticsBaseInterface {
    constructor(private utilsService: UtilsService) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackEvent(action: string, properties: any = {}) {
        if (this.utilsService.isBrowser) {
            console.log('Not Calling Track Events In QA', action, properties);
        } else {
            console.log('Not Calling TrackEvent on Non Browser Devices');
        }
    }

    trackPageNavigationEvent(properties: object) {
        if (this.utilsService.isBrowser) {
            console.log('Not Calling Page Navigation Event in QA', properties);
        } else {
            console.log('Not Calling Page Navigation Event on Non Browser Devices');
        }
    }

    identifyUser(uniqId: string, properties: object) {
        // here unique Id is phone number
        if (this.utilsService.isBrowser) {
            console.log('Not Calling Identify User in QA', uniqId, properties);
        } else {
            console.log('Not Calling Identify User on Non Browser Devices');
        }
    }

    resetOnLogout() {
        if (this.utilsService.isBrowser) {
            console.log('Not Calling Reset On Logout in QA');
        } else {
            console.log('Not Calling Reset On Logout on Non Browser Devices');
        }
    }

    trackGenericProperties(properties: object) {
        if (this.utilsService.isBrowser) {
            console.log('Not Calling Track Generic Property in QA', properties);
        } else {
            console.log('Not Calling Track Generic Property on Non Browser Devices');
        }
    }
}
