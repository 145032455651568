import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(dataArray: any[], orderByKey?: string): any {
        if (orderByKey) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dataArray.sort((a: any, b: any) => {
                if (a[orderByKey] < b[orderByKey]) {
                    return -1;
                } else if (a[orderByKey] > b[orderByKey]) {
                    return 1;
                } else {
                    return 0;
                }
            });
        } else {
            dataArray.sort();
        }
        return dataArray;
    }
}
