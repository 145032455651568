export enum PaymentProviders {
    RAZORPAY = 'RAZORPAY',
    ZERO_AMT_TX = 'ZERO_AMT_TX',
}

export enum PaymentModes {
    HQWALLET = 'HQWALLET',
    INR_PAYMENT = 'INR_PAYMENT',
}

export enum HqWalletTypes {
    INDIVIDUAL = 'INDIVIDUAL',
    CORPORATE = 'CORPORATE',
}

export enum CouponTypes {
    EMI = 'EMI',
    COUPON_OFFER = 'COUPON_OFFER',
}
