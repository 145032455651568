@if (isVisible) {
    <div class="d-flx flx-d-clm snackbar-list">
        @for (snackItem of snackBarItems; track snackItem) {
            <div [ngClass]="snackItem.cssClass">
                <div class="d-flx a-items-c flx-d-row title-container">
                    <div class="d-flx snackbar-icon" [innerHTML]="snackItem.icon"></div>
                    <p class="snackbar-title">{{ snackItem.title }}</p>
                </div>
                @if (snackItem.description) {
                    <p class="snackbar-description">{{ snackItem.description }}</p>
                }
                @if (snackItem.ctaConfig?.show) {
                    <div class="cta-container">
                        <p class="cta-label c-pointer" (click)="handleCTAClick(snackItem)">
                            {{ snackItem.ctaConfig.label }}
                        </p>
                    </div>
                }
                <div
                    [innerHTML]="crossIcon"
                    class="d-flx a-items-c j-cont-cen close-cta c-pointer"
                    (click)="hideSnackBar(snackItem)"
                ></div>
            </div>
        }
    </div>
}
