export enum LoginFormStages {
    ENTER_EMAIL = 'ENTER_EMAIL',
    ENTER_PASSWORD = 'ENTER_PASSWORD',
    CREATE_PASSWORD = 'CREATE_PASSWORD',
    FORGET_PASSWORD = 'FORGET_PASSWORD',
    RECOVERY_MAIL_SUCCESS = 'RECOVERY_MAIL_SUCCESS',
    CREATE_PROFILE = 'CREATE_PROFILE',
}

export enum AuthStatus {
    AUTHENTICATED = 'AUTHENTICATED',
    UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export const AuthStatusMap = {
    [AuthStatus.AUTHENTICATED]: 'LOGGED_IN',
    [AuthStatus.UNAUTHENTICATED]: 'GUEST_USER',
};
