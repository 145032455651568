import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, pairwise } from 'rxjs';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { flexiUrlRegex, meetingRoomsRegex } from '../../../core/constants/urls-regex';

@Component({
    standalone: true,
    selector: 'app-sticky-container',
    template: `<div class="sticky" #stickyContainer></div>`,
})
export class StickyContainerComponent implements OnInit {
    @ViewChild('stickyContainer', { read: ElementRef, static: true }) stickyContainer: ElementRef;
    private elements: HTMLElement[] = [];

    constructor(
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private utilsService: UtilsService
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                pairwise() // To get both previous and current NavigationEnd events
            )
            .subscribe((events: [NavigationEnd, NavigationEnd]) => {
                const previousUrl = events[0].urlAfterRedirects.split('?')?.[0];
                const currentUrl = events[1].urlAfterRedirects.split('?')?.[0];
                if (previousUrl === currentUrl) {
                    return;
                }
                if (
                    this.utilsService.getRegexPatternMatch([flexiUrlRegex.listingPage], previousUrl) >= 0 &&
                    this.utilsService.getRegexPatternMatch([flexiUrlRegex.listingPage], currentUrl) >= 0
                ) {
                    return;
                }
                if (
                    this.utilsService.getRegexPatternMatch([meetingRoomsRegex.listingPage], previousUrl) >= 0 &&
                    this.utilsService.getRegexPatternMatch([meetingRoomsRegex.listingPage], currentUrl) >= 0
                ) {
                    return;
                }
                this.clearElements();
            });
    }

    addElement(elementRef: ElementRef, containerRef: ElementRef, className?: string) {
        if (elementRef.nativeElement) {
            const height = containerRef.nativeElement.clientHeight;

            if (className) {
                this.renderer.addClass(elementRef.nativeElement, className);
            }

            // Append placeholder element
            const placeholderElement = document.createElement('div');
            this.renderer.setStyle(placeholderElement, 'height', `${height}px`);
            this.renderer.appendChild(containerRef.nativeElement, placeholderElement);

            this.renderer.appendChild(this.stickyContainer.nativeElement, elementRef.nativeElement);
            this.elements = [...this.elements, elementRef.nativeElement];
            this.cdr.detectChanges();
        }
    }

    removeElement(elementRef: ElementRef, containerRef: ElementRef, className?: string) {
        if (elementRef.nativeElement) {
            if (className) {
                this.renderer.removeClass(elementRef.nativeElement, className);
            }
            // remove all children of containerRef
            containerRef.nativeElement.innerHTML = '';

            this.renderer.removeChild(this.stickyContainer.nativeElement, elementRef.nativeElement);
            this.renderer.appendChild(containerRef.nativeElement, elementRef.nativeElement);
            this.elements = this.elements.filter(element => element !== elementRef.nativeElement);
            this.cdr.detectChanges();
        }
    }

    clearElements() {
        this.elements.forEach(element => {
            this.renderer.removeChild(this.stickyContainer.nativeElement, element);
        });
        this.cdr.detectChanges();
    }
}
