import { map } from 'rxjs/operators';
import { ObserverQueue } from '../../../classes/observerQueue.class';
import { Injectable, NgZone } from '@angular/core';
import { zip, interval } from 'rxjs';
import { toPromiseAndEnqueueTask } from '../../../decorators/toPromiseAndEnqueueTask.decorator';
import { StorageService } from '../../../../shared/services/storage/storage.service';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const mixpanel: any;

const queue = new ObserverQueue();

@Injectable({
    providedIn: 'root',
})
export class MixPanelService {
    mixpanelSessionKey = 'session_id';
    mixpanelCookieSessionKey = 'mixpanel_session_id';

    init() {
        window['mixpanelService'] = {
            component: this,
            zone: this.ngZone,
            onScriptLoaded: () => this.onScriptLoaded(),
        };
    }

    private generateSessionId(): string {
        const id = Math.random().toString(36).substr(2, 10);
        this.storageService.setCookie(this.mixpanelCookieSessionKey, id, 1, '/');
        return id;
    }

    private getSessionId(): string {
        let sessionId = this.storageService.getCookie(this.mixpanelCookieSessionKey);

        if (!sessionId) {
            sessionId = this.generateSessionId();
        }

        return sessionId;
    }

    private startMixpanelSession() {
        const sessionId = this.getSessionId();
        mixpanel.register({
            [this.mixpanelSessionKey]: sessionId,
        });
    }

    onScriptLoaded() {
        this.startMixpanelSession();
        zip(queue, interval(10))
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .pipe(map(([queueItem, d]) => queueItem))
            .subscribe(({ event, resolve }) => {
                resolve(event());
            });
    }

    @toPromiseAndEnqueueTask(queue)
    identify(userId: string, user) {
        if (userId) {
            mixpanel.identify(userId);
        }
        if (user) {
            mixpanel.people.set(user);
        }
    }

    @toPromiseAndEnqueueTask(queue)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    track(id: string, action: any = {}): void {
        mixpanel.track(id, action);
    }

    @toPromiseAndEnqueueTask(queue)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register(properties: any): void {
        mixpanel.register(properties);
    }

    @toPromiseAndEnqueueTask(queue)
    reset() {
        mixpanel.reset();
    }

    constructor(
        private ngZone: NgZone,
        private storageService: StorageService
    ) {}
}
