import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
    IFooterCMSCTAConfig,
    IFooterCmsMetaDataConfig,
    IParsedFooterCmsMetaDataConfig,
} from './footer-cms-metadata.interfaces';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';

@Component({
    selector: 'app-footer-cms-metadata',
    templateUrl: './footer-cms-metadata.component.html',
    styleUrls: ['./footer-cms-metadata.component.scss'],
})
export class FooterCmsMetadataComponent implements OnInit, AfterViewInit {
    areaMetaDataList: IParsedFooterCmsMetaDataConfig[] = [];

    @Input('config') set updateConfig(config: IFooterCmsMetaDataConfig[]) {
        this.areaMetaDataList = this.parseMetaData(config);
        // Rerunning the overflow function for subsequent re-renders
        if (this.contentContainer) {
            this.checkOverflow();
        }
    }
    @ViewChildren('contentContainer') contentContainer: QueryList<ElementRef>;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    ngAfterViewInit() {
        this.checkOverflow();
    }

    checkOverflow() {
        this.contentContainer.forEach((item, index) => {
            const element = item.nativeElement.querySelector('.content-container');
            let showBtn = false;
            showBtn = element.scrollHeight > element.clientHeight;

            this.areaMetaDataList = this.areaMetaDataList.map((metaData, idx) => {
                if (idx === index) {
                    return {
                        ...metaData,
                        ctaConfig: {
                            ...metaData.ctaConfig,
                            show: showBtn,
                        },
                    };
                } else {
                    return metaData;
                }
            });
        });
    }

    getCTAConfig(isExpanded: boolean): IFooterCMSCTAConfig {
        return {
            label: isExpanded ? 'View Less' : 'View More',
            icon: isExpanded ? CommonIconsUrl.ARROW_UP_PRIMARY : CommonIconsUrl.ARROW_DOWN_PRIMARY,
        };
    }

    parseMetaData(data: IFooterCmsMetaDataConfig[]): IParsedFooterCmsMetaDataConfig[] {
        return (
            data?.map(metaData => {
                return {
                    ...metaData,
                    isExpanded: false,
                    ctaConfig: {
                        ...this.getCTAConfig(false),
                    },
                };
            }) || []
        );
    }

    toggleViewMore(index: number) {
        this.areaMetaDataList = this.areaMetaDataList.map((item, i) => {
            if (index === i) {
                return {
                    ...item,
                    isExpanded: !item.isExpanded,
                    ctaConfig: {
                        ...this.getCTAConfig(!item.isExpanded),
                        show: item.ctaConfig.show,
                    },
                };
            } else {
                return item;
            }
        });
    }
}
