// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ObserverQueue } from './../classes/observerQueue.class';
// Converts Function to Promise and queues up orignal Call

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toPromiseAndEnqueueTask(queue: { push: (e) => any }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function (classRef: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        descriptor.value = (...args): Promise<any> => {
            return new Promise(resolve => {
                queue.push({ event: () => originalMethod.call(classRef, ...args), resolve });
            });
        };
        return descriptor;
    };
}
