import { UtilsService } from '../../utils/utils.service';
import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root',
})
export class SentryService {
    init() {
        if (this.utilsService.getDeviceInfo().isBrowser) {
            Sentry.init({
                dsn: environment.sentryDSN,
                environment: environment.env,
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for tracing.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
                // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
                tracePropagationTargets: [environment.baseUrl],
            });
        }
    }

    constructor(private utilsService: UtilsService) {}

    throwError(errorMessage: string, errorName?: string) {
        const error = new Error(errorMessage);
        if (errorName) {
            error.name = errorName;
        }
        Sentry.captureException(error);
    }
}
