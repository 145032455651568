@if (!deviceInfo.isMobile) {
    @if (!isLoggedIn) {
        <div class="login-signup-btn-container">
            <app-button class="width-100" [field]="loginBtnConfig" (onClick)="handleLoginCTA()"></app-button>
        </div>
    }
    @if (isLoggedIn) {
        <div (clickOutside)="toggleDropdown(false)" appClickOutside>
            <div class="d-flx flx-d-clm my-account-dropdown-container">
                <div
                    class="d-flx a-items-c my-account-dropdown-label-container c-pointer"
                    (click)="toggleDropdown(true)"
                >
                    <p class="label-text font-bold font-12 text-color-dark">{{ dropdownLabel }}</p>
                    <div class="d-flx a-items-c j-cont-cen my-account-dropdown-icon-container">
                        <span class="d-flx caret-down"></span>
                    </div>
                </div>
                @if (showDropdown) {
                    <div class="dropdown-wrapper">
                        <ng-container [ngTemplateOutlet]="dropdownList"></ng-container>
                    </div>
                }
            </div>
        </div>
    }
}

@if (deviceInfo.isMobile) {
    @if (!isLoggedIn) {
        <div class="padding-horizontal-16 padding-vertical-12">
            <app-button class="width-100" [field]="loginBtnConfig" (onClick)="handleMobileLoginCTA()"></app-button>
        </div>
    }
}

<!--templates-->
<ng-template #dropdownList>
    <div class="d-flx flx-d-clm bg-color-white my-account-dropdown-list-container">
        <ng-container
            [ngTemplateOutlet]="dropdownItem"
            [ngTemplateOutletContext]="{ item: logoutDropdownItem }"
        ></ng-container>
    </div>
</ng-template>

<ng-template #dropdownItem let-item="item">
    <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
    <div
        class="d-flx a-items-c my-account-dropdown-item c-pointer"
        [ngClass]="item.cssClass"
        (click)="item.onClick?.()"
    >
        <div class="d-flx a-items-c j-cont-cen dropdown-icon-container">
            <img
                class="height-100 width-100 object-fit-contain"
                appImageShimmerLazyLoad
                [imageSrc]="item.icon.url"
                [alt]="item.icon.alt"
            />
        </div>
        <p class="dropdown-label text-color-dark font-bold font-12">
            {{ item.label }}
            @if (item.comingSoon) {
                <span class="coming-soon-text text-color-x-lighter-grey font-italic">-Coming Soon</span>
            }
        </p>
    </div>
</ng-template>
