import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    LandingPagesUrlInitialRoute,
    MobileAppPagesUrlInitialRoute,
} from './modules/mobile-app-pages/constants/constants';
import { productsMap } from './shared/enums/enums';

// INFO ADD THE ROUTES TO server/routes/index.ts to access urls in build
const routes: Routes = [
    {
        path: LandingPagesUrlInitialRoute,
        loadChildren: () => import('./modules/landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
    },
    {
        path: MobileAppPagesUrlInitialRoute,
        loadChildren: () =>
            import('./modules/mobile-app-pages/mobile-app-pages.module').then(m => m.MobileAppPagesModule),
    },
    {
        path: 'coworking-space/:workspaceSlug',
        loadChildren: () => import('./modules/space-details/space-details.module').then(m => m.SpaceDetailsModule),
    },
    {
        path: 'sp',
        loadChildren: () => import('./modules/static-pages/static-pages.module').then(m => m.StaticPagesModule),
    },
    {
        path: 'guides',
        loadChildren: () => import('./modules/guides/guides.routing').then(m => m.GuidesRoutingModule),
    },
    {
        path: 'flexi',
        loadChildren: () =>
            import('./modules/flexi/routing-modules/flexi-non-city.module').then(m => m.FlexiNonCityModule),
    },
    {
        path: 'dedicated',
        loadChildren: () =>
            import('./modules/dedicated/routing-modules/dedicated-non-city.module').then(m => m.DedicatedNonCityModule),
    },
    {
        path: 'managed-office',
        loadChildren: () =>
            import('./modules/managed-office/routing-modules/managed-office-non-city.module').then(
                m => m.ManagedOfficeNonCityModule
            ),
    },
    {
        path: productsMap.COMMERCIAL_LEASING.listingUrlSlug,
        loadChildren: () =>
            import('./modules/commercial-leasing/routes/commercial-leasing-non-city.routes').then(
                r => r.COMMERCIAL_LEASING_NON_CITY_ROUTE
            ),
    },
    {
        path: 'corporate-flexi',
        loadChildren: () =>
            import('./modules/corporate-flexi/corporate-flexi.routes').then(m => m.CORPORATE_FLEXI_HOME_ROUTES),
    },
    {
        path: 'about-us',
        loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule),
    },
    {
        path: 'termsncondition',
        loadChildren: () =>
            import('./modules/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
    },
    {
        path: 'privacy',
        loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    },
    {
        path: 'refund',
        loadChildren: () => import('./modules/refund-policy/refund-policy.module').then(m => m.RefundPolicyModule),
    },
    {
        path: 'faq',
        loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),
    },
    {
        path: 'community-benefits',
        loadChildren: () =>
            import('./modules/community-benefits/community-benefits.module').then(m => m.CommunityBenefitsModule),
    },
    {
        path: 'industry-reports',
        loadChildren: () =>
            import('./modules/industry-reports/industry-reports.module').then(m => m.IndustryReportsModule),
    },
    {
        path: 'testimonials',
        loadChildren: () =>
            import('./modules/clients-testimonial/clients-testimonial-routing.module').then(
                m => m.ClientsTestimonialRoutingModule
            ),
    },
    {
        path: 'case-studies',
        loadChildren: () =>
            import('./modules/case-studies/case-studies-routing.module').then(m => m.CaseStudiesRoutingModule),
    },
    {
        path: 'resources/office-space-guide',
        loadChildren: () =>
            import('./modules/knowledge-center/knowledge-center.routes').then(m => m.KNOWLEDGE_CENTER_ROUTES),
    },
    {
        path: 'referrals',
        loadChildren: () => import('./modules/refer-and-earn/refer-and-earn.routes').then(m => m.REFER_AND_EARN_ROUTES),
    },
    {
        path: 'contact',
        loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule),
    },
    {
        path: 'app',
        loadChildren: () => import('./modules/download-app/download-app.module').then(m => m.DownloadAppModule),
    },
    {
        path: 'virtual-office',
        loadChildren: () =>
            import('./modules/virtual-office/routing-modules/virtual-office-non-city.module').then(
                m => m.VirtualOfficeNonCityModule
            ),
    },
    {
        path: 'meeting-room',
        loadChildren: () =>
            import('./modules/meeting-rooms/routing-modules/meeting-rooms-non-city.module').then(
                m => m.MeetingRoomsNonCityModule
            ),
    },
    {
        path: 'app-download',
        loadChildren: () => import('./modules/app-download/app-download.module').then(m => m.AppDownloadModule),
    },
    {
        path: 'shortlisted-workspaces',
        loadChildren: () =>
            import('./modules/workspace-shortlists/workspace-shortlists.module').then(m => m.WorkspaceShortlistsModule),
    },
    {
        path: 'list-space',
        loadChildren: () => import('./modules/list-space/list-space.module').then(m => m.ListSpaceModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'rate-your-experience/:bookingId',
        loadChildren: () =>
            import('./modules/user-reviews/routing-modules/user-reviews.module').then(m => m.UserReviewsModule),
    },
    {
        path: 'office-space-for-enterprises',
        loadChildren: () =>
            import('./modules/enterprises/routing-modules/enterprises.module').then(m => m.EnterprisesModule),
    },
    {
        path: 'nexus/auth',
        loadChildren: () => import('./modules/nexus-auth/nexus-auth.module').then(m => m.NexusAuthModule),
    },
    {
        path: ':citySlug',
        loadChildren: () => import('./modules/city-slug-module/city-slug.module').then(m => m.CitySlugModule),
    },
    {
        path: '',
        loadChildren: () => import('./modules/home/home.routes').then(m => m.HOME_ROUTES),
    },
    {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking', scrollPositionRestoration: 'top' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
