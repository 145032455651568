import { IBreadcrumbs } from './breadcrumbs.interface';
import { menuEnum, menuType } from '../component-modules/common-header/enums/enums';
import { BehaviorSubject } from 'rxjs';
import { listingPageCard } from '../enums/enums';
import { IStructuredContent } from './structured-content.interface';
import { IHttpResponse } from './HTTPResponse.interface';
import { HttpErrorResponse } from '@angular/common/http';

export interface IImage {
    url: string;
    alt?: string;
}

export interface IPaginationParam {
    count: number;
    next?: number;
    previous?: number;
}

export interface IAreaMetaData {
    name: string;
    title: string;
    about: string;
    order: number;
}
export interface IMyHQAreaMetaData {
    title: string;
    about: string;
}

export interface ISeoData {
    breadcrumbs: IBreadcrumbs[];
    areametaData?: IAreaMetaData[];
    myHQAreaMetaData?: IMyHQAreaMetaData;
}

export interface IListingSubheadingConfig {
    workspacesHeading: string;
    nearbyWorkspacesHeading?: string;
    unavailableWorkspacesHeading?: string;
}

export interface IMenuItem {
    id: menuEnum;
    imageUrl: string;
    imageUrlSelected: string;
    title: string;
    description: string;
    redirectUrl: BehaviorSubject<string>;
    comingSoon: boolean;
    show: boolean;
    type: menuType;
    subMenus?: IMenuItem[];
}

export interface ICancellationPolicyData {
    title: string;
    description?: string;
    dataList: { texts: string[] }[];
}

export interface ICancellationPolicy {
    header: { title: string };
    dataList: { texts: string[] }[];
}

export interface ICancellationPolicyDataConfig {
    data: ICancellationPolicyData;
    cancellationAllowed: boolean;
}

export interface IListingPanel {
    position: number;
    type: listingPageCard;
    data: {
        items: { content: IStructuredContent[] }[];
    };
}

export interface IBEFilter {
    type: string;
    title: string;
    id: string;
    data: { id: string; title: string }[];
    defaultValue?: string;
}

export type IHandleHttpResponseErrorOptions<T extends IHttpResponse<unknown>> = {
    message?: string;
    /**
     * @default false
     * @description
     * Propagates the error to the consumer.
     * This will not prevent the default handling from triggering
     */
    propagate?: boolean;
    /**
     * Run side-effects for API Error
     * @example
     * onError(err) {
     *     navCtrl.pop();
     *     if (err.kind === APIErrorKind.SUCCESS_FALSE) {
     *         // res object can be accessed here
     *         console.log(err.res.data.workspaces, err.message);
     *     } else if (err.kind === APIErrorKind.HTTP_ERROR {
     *         // original httpError can be accessed here
     *         console.log(err.httpError);
     *     }
     * }
     */
    onError?: (err: ApiError<T>) => void;
};

export enum APIErrorKind {
    SUCCESS_FALSE,
    HTTP_ERROR,
}

export type ApiError<T> =
    | {
          kind: APIErrorKind.HTTP_ERROR;
          message: string;
          httpError: HttpErrorResponse;
      }
    | {
          kind: APIErrorKind.SUCCESS_FALSE;
          message: string;
          res: T;
      };
