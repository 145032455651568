export enum ChatBtnPositionEnum {
    DEFAULT = 'bottomleft',
    BOTTOM_RIGHT = 'bottomright',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    BOTTOM_LEFT = 'bottomleft',
    RIGHT = 'right',
    LEFT = 'left',
    TOP_RIGHT = 'topright',
    TOP_LEFT = 'topleft',
}
