import { Directive, Input, OnInit, ViewContainerRef, Renderer2, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ButtonsComponent } from '../../ui-components/buttons/buttons.component';
import { IButtonConfig } from '../../interfaces/button.interface';

@Directive({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formButton]',
})
export class FormButtonDirective implements OnInit {
    @Input() button: IButtonConfig;
    @Input() form: UntypedFormGroup;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() buttonClickEvent = new EventEmitter<any>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentRef: any;

    constructor(
        private container: ViewContainerRef,
        private renderer: Renderer2
    ) {}

    ngOnInit() {
        if (Object.keys(this.button).length === 0) {
            return;
        }

        this.componentRef = this.container.createComponent(ButtonsComponent);
        this.componentRef.instance.field = this.button;
        this.componentRef.instance.form = this.form;

        this.renderer.listen(this.componentRef.location.nativeElement, 'click', event => {
            this.buttonClickEvent.emit(event);
        });
    }
}
