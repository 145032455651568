export const urlRegex = {
    productPageWithoutCitySlug: /^(?:\/flexi|\/dedicated|\/corporate-flexi|\/virtual-office)$/i,
    homePage: /^\/?$/i,
    flexiNonCityRedirectPages: /^\/flexi(?:\/plans|\/free-trial)$/i,
    listingPages:
        /^\/(?:([^\/]+?))(?:\/([^\/]+?))?\/((dedicated-desks|private-cabins|coworking-space|work-cafes|virtual-office)-in-)(?:([^\/]+?))\/?$/i,
    // This also matches all the static urls => for proper usage make sure you eliminate the static urls first => refer staticUrlsRegex
    homePageWithCitySlug: /^\/[^/]+$/,
};

// TODO :: we can use the flexi and dedicated url regexes instead of maintaining the extra regex for common listing page (i.e urlRegex.listingPages)
export const flexiUrlRegex = {
    productPage: /^\/(?:([^\/]+?))?\/flexi\/?$/i,
    listingPage: /^\/(?:([^\/]+?))\/flexi\/((coworking-space|work-cafes)-in-)(?:([^\/]+?))\/?$/i,
    spaceDetailPage: /^\/flexi\/coworking-space\/(?:([^\/]+?))\/?$/i,
    bookingPages: /^\/flexi\/book\/[^?]+(\?.*)?$/i,
    cityRedirectPages: /^\/flexi(?:\/plans|\/free-trial)$/i,
    allUrls: /^(?:\/([^\/]+?))?\/flexi(.*)\/?$/i,
};

export const dedicatedUrlRegex = {
    productPage: /^\/(?:([^\/]+?))?\/dedicated\/?$/i,
    listingPage:
        /^\/(?:([^\/]+?))\/dedicated\/((dedicated-desks|private-cabins|coworking-space)-in-)(?:([^\/]+?))\/?$/i,
    spaceDetailPage: /^\/dedicated\/coworking-space\/(?:([^\/]+?))\/?$/i,
    allUrls: /^(?:\/([^\/]+?))?\/dedicated(.*)\/?$/i,
};

export const managedOfficeUrlRegex = {
    allUrls: /^(?:\/([^\/]+?))?\/managed-office([^?]*)(?:\?(.*))?$/i,
    spaceDetailPage: /^\/managed-office\/([^\/]+?)\/?(?:\?(.*))?$/i,
    listingPage: /\/[^\/]+\/managed-office\/[^\/?]+(?:\?[^\/]+)?$/i,
};
export const commercialLeasingUrlRegex = {
    allUrls: /^(?:\/([^\/]+?))?\/office-space-for-rent([^?]*)(?:\?(.*))?$/i,
    listingPage: /\/[^\/]+\/office-space-for-rent\/[^\/?]+(?:\?[^\/]+)?$/i,
    spaceDetailPage: /^\/office-space-for-rent\/([^\/]+?)\/?(?:\?(.*))?$/i,
};

export const corporateFlexiUrlRegex = {
    allUrls: /^(?:\/([^\/]+?))?\/corporate-flexi(.*)\/?$/i,
};

export const virtualOfficeUrlRegex = {
    productPage: /^\/(?:([^\/]+?))?\/virtual-office\/?(?:\?(.*))?$/i,
    listingPage: /^\/(([\w-]+\/)?virtual-office\/)?virtual-office-in-([\w-]*)?(\?.*)?$/,
    paymentsPage: /^\/virtual-office\/payments\/[^?]+(\?.*)?$/i,
    compareWorkspacePage: /^\/virtual-office\/compare\/workspaces(?:\/.*)?(?:\?.*)?$/i,
    allUrls: /^(?:\/([^\/]+?))?\/virtual-office(.*)\/?$/i,
    spaceDetailPage: /^\/virtual-office\/(?:([^\/]+?))\/?$/i,
};

export const meetingRoomsRegex = {
    allUrls: /^(?:\/([^\/]+?))?\/meeting-room([^?]*)(?:\?(.*))?$/i,
    spaceDetailPage: /^\/meeting-room\/([^\/]+?)\/?(?:\?(.*))?$/i,
    //   Matching /<city>/meeting-room/<city | region>?<queryParams>
    listingPage: /\/[^\/]+\/meeting-room\/[^\/?]+(?:\?[^\/]+)?$/i,
    paymentsPage: /^\/meeting-room\/payment\/[^?]+(\?.*)?$/i,
};

export const brandUrlRegex = {
    listingPage: /^\/(([\w-]+\/)?brand\/)([\w\/-]+)-in-([\w-]*)?(\?.*)?$/,
};

export const userReviewRegex = {
    rateYourExperience: /^\/rate-your-experience(\/?.*)?$/i,
};

export const authPagesRegex = {
    login: /^(?:\/([^\/]+?))?\/login(.*)\/?$/i,
};

export const industryReportsRegex = {
    industryReports: /\/industry-reports/,
};
export const testimonialsRegex = {
    testimonials: /^\/testimonials(?:\/[^/]+)?$/,
};

export const caseStudyRegex = {
    allUrls: /^(?:\/([^\/]+?))?\/case-studies([^?]*)(?:\?(.*))?$/i,
    caseStudyDetailPage: /^\/case-studies\/([^\/]+?)\/?(?:\?(.*))?$/i,
    listingPage: /\/case-studies(?:\/[^\/?]+)?(?:\?[^\/]+)?$/i,
};
export const knowledgeCenterRegex = {
    allUrls: /^(?:\/([^\/]+?))?\/resources\/office-space-guide([^?]*)(?:\?(.*))?$/i,
};
export const referAndEarnRegex = {
    allUrls: /^(?:\/([^\/]+?))?\/referrals([^?]*)(?:\?(.*))?$/i,
};
export const guidesRegex = {
    guides: /^\/guides\/.*/,
};

export const footerDisabledUrls = [
    /^\/lp\/(.*)\/?(?=\/|$)/i,
    /^\/dedicated\/visit(.*)\/?$/i,
    /^(?:([^\/]+?))?\/flexi\/free-trial(.*)\/?$/i,
    /^(?:([^\/]+?))?\/flexi\/plans\/checkout(.*)\/?(?=\/|$)/i,
    /^(?:([^\/]+?))?\/flexi\/plans\/confirmation(.*)\/?$/i,
    /^(?:([^\/]+?))?\/flexi\/plans\/success(.*)\/?$/i,
    flexiUrlRegex.bookingPages,
    meetingRoomsRegex.paymentsPage,
];

export const primaryFooterDisabledUrls = [
    virtualOfficeUrlRegex.paymentsPage,
    userReviewRegex.rateYourExperience,
    authPagesRegex.login,
    meetingRoomsRegex.paymentsPage,
    industryReportsRegex.industryReports,
    flexiUrlRegex.bookingPages,
];

export const secondaryFooterProductCategoryDisabledLinks = [
    virtualOfficeUrlRegex.paymentsPage,
    userReviewRegex.rateYourExperience,
    authPagesRegex.login,
    meetingRoomsRegex.paymentsPage,
    industryReportsRegex.industryReports,
    flexiUrlRegex.bookingPages,
];

export const checkoutWithLogoClickableUrl = [
    /^(?:\/([^\/]+?))?\/flexi\/plans\/success\/(.*)\/?$/i,
    /^(?:\/([^\/]+?))?\/dedicated\/visit\/success\/(.*)\/?$/i,
];

export const checkoutWithCityUrls = [/^(?:\/([^\/]+?))?\/flexi\/free-trial(.*)\/?$/i];

export const checkoutHeaderUrls = [
    /^(?:\/([^\/]+?))?\/flexi\/plans\/checkout\/?$/i,
    /^(?:\/([^\/]+?))?\/flexi\/plans\/confirmation\/?$/i,
];

export const referralHeaderUrls = [/^(?:\/([^\/]+?))?\/flexi\/referral(.*)\/?$/i];

export const automaticCityRoutingDisabledUrls = [urlRegex.listingPages];

// Regex for static pages with support for optional query params
export const staticPagesURLRegex = [
    /^\/about-us(\/?.*)?$/i,
    /^\/sp(\/?.*)?$/i,
    /^\/profile\/(\/?.*)?$/i,
    /^\/termsncondition(\/?.*)?$/i,
    /^\/privacy(\/?.*)?$/i,
    /^\/refund(\/?.*)?$/i,
    /^\/faq(\/?.*)?$/i,
    /^\/community-benefits(\/?.*)?$/i,
    /^\/app-download(\/?.*)?$/i,
    /^\/list-space(\/?.*)?$/i,
];

export const nexusAuthPagesRegex = {
    allUrls: /^\/nexus\/auth\/.*/,
};

export const landingPagesRegex = {
    managedOffice: /^\/lp\/[^\/]+\/managed-office$/,
    officeSpace: /^\/lp\/[^\/]+\/office-space$/,
    voPanIndia: /^\/lp\/virtual-office$/,
    allUrls: /^\/lp\/.*/,
};

export const spaceVisitRegex = {
    myShortlist: /\/shortlisted-workspaces(\?.*)?/,
    scheduleVisit: /\/(dedicated|flexi|virtual-office)\/schedule-visit(\?.*)?/,
};
