import { BrowserStateInterceptor } from './core/interceptors/browserstate.interceptor';
import { APP_INITIALIZER, APP_ID, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CommonFooterModule } from './shared/component-modules/common-footer/common-footer.module';
import { UnsupportedCityProductModalComponent } from './shared/standalone-components/unsupported-product-modal/unsupported-city-product-modal.component';
import { LocationService } from './shared/services/location/location.service';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { SentryInterceptor } from './core/interceptors/sentry.interceptor';
import { provideLottieOptions } from 'ngx-lottie';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { AppPrefixBannerComponent } from './shared/standalone-components/app-download-banner/app-prefix-banner.component';
import { CommonHeaderComponent } from './shared/component-modules/common-header/common-header.component';
import { StickyContainerComponent } from './shared/layouts/sticky-container/sticky-container.component';
import { StickySectionDirective } from './shared/directives/sticky-section/sticky-section.directive';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        analytics: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        webengage: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        google: any;
    }
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CommonHeaderComponent,
        CommonFooterModule,
        AppPrefixBannerComponent,
        UnsupportedCityProductModalComponent,
        StickyContainerComponent,
        StickySectionDirective,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BrowserStateInterceptor,
            multi: true,
        },
        {
            provide: APP_ID,
            useValue: 'hq-web-ssr',
        },
        CookieService,
        LocationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SentryInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                extractor(error: Error, defaultExtractor: (error: unknown) => unknown): unknown {
                    return defaultExtractor(error);
                },
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        provideClientHydration(withNoHttpTransferCache()),
        provideAnimationsAsync(),
    ],
})
export class AppModule {}
