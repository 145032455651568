import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { Component } from '@angular/core';
import { CommonIconsUrl } from '../../../core/constants/common-icons-url';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { filter } from 'rxjs/operators';
import { flexiUrlRegex, virtualOfficeUrlRegex } from '../../../core/constants/urls-regex';

import { OptimizedImageComponent } from '../../app-components/optimized-image/optimized-image.component';
import { VirtualOfficeBannerComponent } from './components/virtual-office-banner.component';
import { StorageService } from '../../services/storage/storage.service';
import { PrefixBannerService } from '../../prefix-banner/prefix-banner.service';
import { productKeys } from '../../enums/enums';
import { AsyncPipe } from '@angular/common';

@Component({
    standalone: true,
    selector: 'app-prefix-banner',
    templateUrl: './app-prefix-banner.component.html',
    styleUrls: ['./app-prefix-banner.component.scss'],
    imports: [OptimizedImageComponent, VirtualOfficeBannerComponent, AsyncPipe],
})
export class AppPrefixBannerComponent {
    protected readonly productKeys = productKeys;
    bannerInstance$ = this.prefixBannerService.getBannerInstance();
    deviceInfo = this.utilsService.getDeviceInfo();
    closeIcon = { url: CommonIconsUrl.CLOSE_WHITE, alt: 'offer-ticket' };

    constructor(
        private utilsService: UtilsService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private storageService: StorageService,
        private prefixBannerService: PrefixBannerService
    ) {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                // Add Urls for Flexi here
                if (
                    this.utilsService.getRegexPatternMatch([flexiUrlRegex.productPage], event.url) >= 0 &&
                    !this.utilsService.isiOSSafari()
                ) {
                    this.prefixBannerService.setAndShow({ type: productKeys.FLEXI });
                } else {
                    this.prefixBannerService.hide();
                }

                // Add Urls for Virtual Office here
                const leadSubmitted = this.storageService.getSession('vo-header-lead');
                if (
                    this.utilsService.getRegexPatternMatch(
                        [
                            virtualOfficeUrlRegex.listingPage,
                            virtualOfficeUrlRegex.productPage,
                            virtualOfficeUrlRegex.spaceDetailPage,
                        ],
                        event.url
                    ) >= 0 &&
                    !leadSubmitted
                ) {
                    this.prefixBannerService.setAndShow({ type: productKeys.VIRTUAL_OFFICE });
                } else {
                    this.prefixBannerService.hide();
                }
            }
        });
    }

    hideBanner() {
        this.prefixBannerService.hide();
    }

    redirectToDownloadApp() {
        this.trackAppDownloadAnalytics();
        window.open(this.utilsService.getAppDownloadLink(), '_blank');
    }

    trackAppDownloadAnalytics() {
        this.analyticsService.trackAppDownloadRedirectAction({
            RedirectType: this.deviceInfo.os,
            position: 'FLEXI_TOP_BANNER',
        });
    }
}
