import { urlRegex, flexiUrlRegex } from './../../../core/constants/urls-regex';
import { UtilsService } from './../../../core/services/utils/utils.service';
import { Router } from '@angular/router';
import { SelectCityService } from './../select-city/select-city.service';
import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RedirectManagerService {
    selectedCitySub = this.selectCityService.getSelectedCitySync();

    appendCitySlug = (url: string, citySlug: string) => {
        if (this.utilsService.getRegexPatternMatch([urlRegex.homePage], url) >= 0) {
            return `/${citySlug}`;
        }
        return `/${citySlug}${url}`;
    };

    productPageWithCityRouter = (url: string, citySlug: string) => {
        const urlArr = url.split('/');
        const urlFirstPath = urlArr[1];
        return `/${citySlug}/${urlFirstPath}`;
    };

    cityChangeRedirectConfig = [
        {
            reqUrlPattern: urlRegex.productPageWithoutCitySlug,
            redirectFunction: this.productPageWithCityRouter,
        },
        {
            reqUrlPattern: urlRegex.homePage,
            redirectFunction: this.appendCitySlug,
        },
        {
            reqUrlPattern: flexiUrlRegex.cityRedirectPages,
            redirectFunction: this.appendCitySlug,
        },
    ];

    private getRedirectLink(url: string, citySlug: string) {
        let redirectUrl;
        this.cityChangeRedirectConfig.find(redirectObj => {
            if (!redirectObj.reqUrlPattern.test(url)) {
                return;
            }
            redirectUrl = redirectObj.redirectFunction(url, citySlug);
            return redirectUrl;
        });
        return redirectUrl;
    }

    startCityChangeRedirectManager() {
        combineLatest([this.utilsService.getRouterEvents$(), this.selectedCitySub])
            .pipe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                filter(([_, { city }]) => !!city),
                take(1)
            )
            .subscribe(([routeInfo, { city }]) => {
                const currUrl = routeInfo.urlAfterRedirects;
                const [urlWithoutParams, queryParams] = currUrl.split('?');

                let redirectLink = this.getRedirectLink(urlWithoutParams, city.slug);
                if (!redirectLink) {
                    return;
                }
                if (queryParams) {
                    redirectLink = redirectLink + `?${queryParams}`;
                }
                this.router.navigateByUrl(redirectLink);
            });
    }

    startRedirectManagerService() {
        this.startCityChangeRedirectManager();
    }

    constructor(
        private selectCityService: SelectCityService,
        private router: Router,
        private utilsService: UtilsService
    ) {}
}
