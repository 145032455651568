import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';
import { IHttpResponse } from '../../../shared/interfaces/HTTPResponse.interface';
import { APIErrorKind, IHandleHttpResponseErrorOptions } from '../../../shared/interfaces/common.interface';
import { ErrorMsg } from '../../constants/error-msg';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    private getErrorMessage = (err: HttpErrorResponse) => {
        if (err.error) {
            return `An error occurred: ${err.error.reason || err.message}`;
        }

        return `Server returned code: ${err.status}, error message is: ${err.message}`;
    };

    handleError = (err: HttpErrorResponse) => {
        return throwError(() => this.getErrorMessage(err));
    };

    handleHTTPResponseSuccessFalse = <T extends IHttpResponse<unknown>>(
        res: T,
        options?: IHandleHttpResponseErrorOptions<T>
    ) => {
        const message = options?.message ?? res.reason ?? ErrorMsg.serverError;
        const propagate = options?.propagate ?? false;

        if (!res.success) {
            if (propagate) {
                throw new Error(message);
            }

            if (options?.onError) {
                options.onError({
                    kind: APIErrorKind.SUCCESS_FALSE,
                    message,
                    res,
                });
            }
        }
    };

    handleHTTPResponseError = <T extends IHttpResponse<unknown>>(
        err: HttpErrorResponse,
        options?: IHandleHttpResponseErrorOptions<T>
    ) => {
        const message = options?.message ?? this.getErrorMessage(err);
        const propagate = options?.propagate ?? false;

        if (propagate) {
            return throwError(() => new Error(message));
        }

        if (options?.onError) {
            options.onError({
                kind: APIErrorKind.HTTP_ERROR,
                message,
                httpError: err,
            });
        }

        return EMPTY;
    };
}
