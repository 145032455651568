import { FormsModule } from '../forms/forms.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputWithButtonComponent } from './input-with-button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldDirective } from '../../directives/form-field/form-field.directive';
import { FormButtonDirective } from '../../directives/form-button/form-button.directive';

@NgModule({
    declarations: [InputWithButtonComponent],
    imports: [CommonModule, ReactiveFormsModule, FormsModule, FormFieldDirective, FormButtonDirective],
    exports: [InputWithButtonComponent],
})
export class InputWithButtonModule {}
